// @hooks/usePhaseFlow.ts
import { useState, useEffect, useCallback } from "react";
import { PhaseFlow, Phase } from "@model/documents/phaseFlow";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import {Ok} from "@model/Ok";
import * as IO from "fp-ts/IO";

// Helper function to map a document type to the corresponding phase list.
const getPhasesForDocumentType = (
    documentType: "scratch" | "edit" | "example" | "undetermined"
): Phase[] => {
    switch (documentType) {
        case "undetermined":
            return ["documentWizardTypeSelection"];
        case "edit":
            return ["upload"];
        case "scratch":
            return ["documentName", "intentions", "outline"];
        case "example":
            return ["upload", "documentName", "intentions", "outline"];
    }
};

export function usePhaseFlow(documentType: "scratch" | "edit" | "example" | "undetermined") {
    // Holds the PhaseFlow instance as an Option.
    const [phaseFlowOption, setPhaseFlowOption] = useState<O.Option<PhaseFlow>>(O.none);
    // Stores the current phase as an Option.
    const [currentPhase, setCurrentPhase] = useState<O.Option<Phase>>(O.none);

    // Create the phase flow whenever the document type changes.
    useEffect(() => {
        const phases = getPhasesForDocumentType(documentType);
        const creation = PhaseFlow.create(phases);
        if (creation._tag === "Right") {
            setPhaseFlowOption(O.some(creation.right));
            setCurrentPhase(O.some(creation.right.currentPhase()));
        } else {
            console.error(creation.left);
            setPhaseFlowOption(O.none);
            setCurrentPhase(O.none);
        }
    }, [documentType]);

    // Advances to the next phase, using fp‑ts Option combinators.
    const nextPhase: IO.IO<Ok> = useCallback(() => {
        setCurrentPhase(
            pipe(
                phaseFlowOption,
                O.flatMap(flow => flow.next())
            )
        );
        return Ok;
    }, [phaseFlowOption]);

    // Moves to the previous phase.
    const previousPhase: IO.IO<Ok> = useCallback(() => {
        setCurrentPhase(
            pipe(
                phaseFlowOption,
                O.flatMap(flow => flow.previous())
            )
        );
        return Ok;
    }, [phaseFlowOption]);

    // Resets the phase flow to the beginning.
    const reset: IO.IO<Ok> = useCallback(
        () => {
            pipe(
                phaseFlowOption,
                O.map(flow => {
                    flow.reset();
                    setCurrentPhase(O.some(flow.currentPhase()));
                })
            );
            return Ok;
        },
        [phaseFlowOption]
    );

    const hasNext = pipe(
        phaseFlowOption,
        O.fold(
            () => false,
            flow => flow.hasNext()
        )
    );

    const hasPrevious = pipe(
        phaseFlowOption,
        O.fold(
            () => false,
            flow => flow.hasPrevious()
        )
    );

    return {
        currentPhase,    // Option<Phase>
        hasNext,         // boolean
        hasPrevious,     // boolean
        nextPhase,       // IO<Ok>
        previousPhase,   // IO<Ok>
        reset,   // IO<Ok>
    };
}

