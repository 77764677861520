// @styles/DocumentCreation.style
import { StyleSheet, Dimensions } from 'react-native';

const { width } = Dimensions.get('window');

const isMobile = width <= 768;

export const DocumentCreationStyles = StyleSheet.create({
    container: {
        padding: 16,
    },
    containerDoc: {
        padding: 16,
        width: '100%'
    },
    containerDocNew: {
        flex: 1,
        gap: 5,
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: 5
    },
    heading: {
        paddingVertical: 6,
    },
    button2: {
        paddingVertical: 5,
        paddingHorizontal: 20,
        paddingBottom: 10,
        backgroundColor: '#296a6a',
        borderRadius: 5,
        cursor: 'pointer',
    },
    button3: {
        paddingVertical: 5,
        paddingHorizontal: 20,
        paddingBottom: 10,
        backgroundColor: '#053a3a',
        borderRadius: 5,
        cursor: 'pointer',
        marginBottom: 10
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    input: {
        height: 40,
        borderColor: '#ddd',
        backgroundColor: '#fff',
        borderWidth: 1,
        borderRadius: 5,
        color: 'black',
        marginBottom: 16,
        padding: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 2,
    },

    multiline: {
        height: 200,
        backgroundColor: '#fff',
        borderColor: '#ddd',
        borderWidth: 1,
        color: 'black',
        borderRadius: 5,
        marginBottom: 16,
        padding: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 2,
    },
    viewStyle: {
        flex: 1,
        gap: 6,
        flexDirection: 'row',
        justifyContent: 'space-between'
    }, 
});
