// @model/clients/LifecycleApi.ts
import * as t from 'io-ts';
import {
    DocumentID,
    DocumentMetadata,
    OutlineSection,
    UserDocument,
} from '@model/documents/core';

import {FileCodec} from "@model/clients/ContentApi"

export const DocumentWizardType = t.union([t.literal("scratch"), t.literal("example"), t.literal("edit")]);
export type DocumentWizardType = t.TypeOf<typeof DocumentWizardType>;

// 1. Scratch: Create document from scratch (existing flow)
export const CreateDocumentScratchRequest = t.type({
    type: t.literal("scratch"),
    outline: t.string,
    style: t.string,
    name: t.string,
});
export type CreateDocumentScratchRequest = t.TypeOf<typeof CreateDocumentScratchRequest>;

// 2. Example: Create new document using an example upload
export const CreateDocumentExampleRequest = t.type({
    type: t.literal("example"),
    outline: t.string,
    style: t.string,
    name: t.string,
    fileName: t.string,
    // Use t.unknown for the file blob (or a custom Blob codec if available)
    file: FileCodec,
});
export type CreateDocumentExampleRequest = t.TypeOf<typeof CreateDocumentExampleRequest>;

// 3. Edit: Edit an existing document via upload (skips name/outline submission)
export const CreateDocumentEditRequest = t.type({
    type: t.literal("edit"),
    fileName: t.string,
    file: FileCodec,
});
export type CreateDocumentEditRequest = t.TypeOf<typeof CreateDocumentEditRequest>;

// Combined Document Creation Request union type
export const CreateDocumentRequest = t.union([
    CreateDocumentScratchRequest,
    CreateDocumentExampleRequest,
    CreateDocumentEditRequest,
]);
export type CreateDocumentRequest = t.TypeOf<typeof CreateDocumentRequest>;

export const CreateDemographicRequest = t.type({
    user_title: t.string,
    user_intention: t.string,
});
export type CreateDemographicRequest = t.TypeOf<typeof CreateDemographicRequest>;

export const CreateDocumentResponse = t.type({
    prompting_question: t.string,
    document_id: DocumentID,
});
export type CreateDocumentResponse = t.TypeOf<typeof CreateDocumentResponse>;

export const ListAvailableDocumentsResponse = t.type({
    documents: t.array(DocumentMetadata),
});
export type ListAvailableDocumentsResponse = t.TypeOf<typeof ListAvailableDocumentsResponse>;

export const DocumentFetchResponse = UserDocument;
export type DocumentFetchResponse = t.TypeOf<typeof DocumentFetchResponse>;

export const OutlineResponse = t.type({
    outline: t.array(OutlineSection),
});
export type OutlineResponse = t.TypeOf<typeof OutlineResponse>;

// Define LifecycleError type
export type LifecycleError =
    | { type: 'NetworkError'; message: string }
    | { type: 'ServerError'; message: string; statusCode: number }
    | { type: 'ValidationError'; message: string }
    | { type: 'DownloadError'; message: string }
    | { type: 'UnknownError'; message: string };
