// @utils/errorHandlers/feedbackErrorHandler.ts

import { FeedbackError } from '@model/clients/FeedbackApi';
import * as IO from 'fp-ts/lib/IO';
import {Ok} from "@model/Ok"; // Import IO utilities

export const handleFeedbackErrorIo = (
    error: FeedbackError,
    setError: (message: string) => IO.IO<Ok>
): IO.IO<Ok> => {
    switch (error.type) {
        case 'NetworkError':
            return setError('Network error. Please check your internet connection.');
        case 'ValidationError':
            return setError('The feedback could not be processed due to a validation error. Please review your input and try again.');
        case 'ServerError':
            return setError(`Server error: ${error.message} (Status code: ${error.statusCode})`);
        case 'FeedbackNotFound':
            return setError(`Feedback not found for ID: ${error.feedbackId}. Please try again or contact support.`);
        case 'UnknownError':
        default:
            console.error(error);
            return setError('An unknown error occurred. Please try again.');
    }
};
