import React, { useState } from "react";
import {
  View,
  TextInput,
  TouchableOpacity,
  Text,
  StyleSheet,
} from "react-native";
import { useIoState } from "@utils/useIoState";
import * as T from "fp-ts/lib/Task";
import { DemographicInformation } from "@model/clients/PaymentApi";
import validator from 'validator';
import {Ok} from "@model/Ok"; // Import validator

interface DemographicSurveyFormProps {
  onSubmit: (survey: DemographicInformation) => T.Task<Ok>;
}

const DemographicSurveyForm: React.FC<DemographicSurveyFormProps> = ({
                                                                       onSubmit,
                                                                     }) => {
  const [userTitle, setUserTitle] = useIoState("");
  const [userIntention, setUserIntention] = useIoState("");
  const [userZipcode, setUserZipcode] = useIoState("");
  const [userIndustry, setUserIndustry] = useIoState("");

  const [errors, setErrors] = useState({
    industry: false,
    title: false,
    intention: false,
    zipcode: false,
    zipcodeErrorText: "",
  });

  const validateForm = (): boolean => {
    let isValid = true;
    const newErrors = {
      industry: userIndustry.trim() === "",
      title: userTitle.trim() === "",
      intention: userIntention.trim() === "",
      zipcode: false,
      zipcodeErrorText: "",
    };

    if (newErrors.title || newErrors.intention || newErrors.industry) {
      isValid = false;
    }

    // ZIP code validation: 5-digit or ZIP+4 (9-digit)
    if (
        !validator.isPostalCode(userZipcode.trim(), "US") &&
        !/^\d{5}-\d{4}$/.test(userZipcode.trim()) // ZIP+4 format check
    ) {
      newErrors.zipcode = true;
      newErrors.zipcodeErrorText =
          "Please enter a valid 5-digit or 9-digit ZIP code.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (): void => {
    if (validateForm()) {
      const demographicData: DemographicInformation = {
        user_industry: userIndustry,
        user_title: userTitle,
        user_intention: userIntention,
        user_zipcode: userZipcode,
      };

      void onSubmit(demographicData)();
    }
  };

  return (
      <View style={styles.mainContainer}>
        <View style={styles.container}>
          <Text style={styles.title}>Demographic Survey</Text>

          {/* Industry Field */}
          <View style={styles.formRow}>
            <Text style={styles.label}>Industry:</Text>
            <TextInput
                style={[
                  styles.input,
                  errors.title ? styles.inputError : null,
                ]}
                value={userIndustry}
                onChangeText={(text) => setUserIndustry(text)()}
                placeholder="e.g. Business Services, Real Estate, Healthcare, etc."
            />
          </View>
          {/* Title Field */}
          <View style={styles.formRow}>
            <Text style={styles.label}>Title:</Text>
            <TextInput
                style={[
                  styles.input,
                  errors.title ? styles.inputError : null,
                ]}
                value={userTitle}
                onChangeText={(text) => setUserTitle(text)()}
                placeholder="e.g., Engineer, Student, etc."
            />
          </View>

          {/* Intention Field */}
          <View style={styles.formRow}>
            <Text style={styles.label}>Intention:</Text>
            <TextInput
                style={[
                  styles.input,
                  errors.intention ? styles.inputError : null,
                ]}
                value={userIntention}
                multiline
                onChangeText={(text) => setUserIntention(text)()}
                placeholder="What do you want to achieve?"
            />
          </View>

          {/* Business Zipcode Field */}
          <View style={styles.formRow}>
            <Text style={styles.label}>Business Zipcode:</Text>
            <TextInput
                style={[
                  styles.input,
                  errors.zipcode ? styles.inputError : null,
                ]}
                value={userZipcode}
                keyboardType="numeric"
                onChangeText={(text) => setUserZipcode(text)()}
                placeholder="Enter your 5- or 9-digit zipcode"
            />
          </View>
          {errors.zipcode && (
              <Text style={styles.errorText}>{errors.zipcodeErrorText}</Text>
          )}

          {/* Privacy Statement */}
          <Text style={styles.privacyText}>
            This information is for internal marketing purposes only and will not
            be sold or shared with third parties.
          </Text>

          {/* Submit Button */}
          <TouchableOpacity style={styles.button} onPress={handleSubmit}>
            <Text style={styles.buttonText}>Submit</Text>
          </TouchableOpacity>
        </View>
      </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    padding: 20,
    width: "90%",
    backgroundColor: "#fff",
    borderRadius: 8,
    elevation: 3,
  },
  title: {
    fontSize: 24,
    color: "#000",
    fontWeight: "bold",
    marginBottom: 20,
    alignSelf: "center",
  },
  formRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
  },
  label: {
    width: "30%",
    fontSize: 16,
    color: "#333",
    fontWeight: "500",
  },
  input: {
    flex: 1,
    height: 40,
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 4,
    paddingHorizontal: 8,
    fontSize: 16,
    color: "#000",
  },
  inputError: {
    borderColor: "red",
  },
  errorText: {
    fontSize: 12,
    color: "red",
    marginBottom: 10,
    alignSelf: "flex-start",
  },
  privacyText: {
    marginTop: 10,
    marginBottom: 20,
    fontSize: 12,
    color: "#555",
    textAlign: "center",
    fontStyle: "italic",
  },
  button: {
    backgroundColor: "#296a6a",
    paddingVertical: 10,
    borderRadius: 5,
    alignItems: "center",
  },
  buttonText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
});

export default DemographicSurveyForm;
