// @navigation/navigators/Workspace.navigator.tsx
import React, {useMemo} from "react";
import {
    createDrawerNavigator,
    DrawerContentScrollView,
    DrawerItem,
    DrawerContentComponentProps,
} from "@react-navigation/drawer";
import DocumentSelectionPage from "@pages/workspace/DocumentSelection.page";
import DocumentCreationPage from "@pages/workspace/DocumentCreation.page";
import FeedbackSubmissionPage from "@pages/workspace/FeedbackSubmission.page";
import AccountManagementPage from "@pages/workspace/AccountManagement.page";
import { AppStackParamList, WorkspaceRoutesParamList } from "@navigation/NavigationRouteParameters";
import { AppRoutes, AuthenticationRoutes, WorkspaceRoutes } from "@navigation/RouteEnums";
import { PaymentClientProvider } from "@contexts/PaymentClient.context";
import { MaterialIcons } from "@expo/vector-icons";
import { StackNavigationProp } from "@react-navigation/stack";
import { enabledFeatures, FeaturesEnum } from "@utils/enabledFeatures";
import { AccountClientProvider } from "@contexts/AccountClient.context";
import withAppNavigator from "@navigation/helpers/withAppNavigator";
import { WorkspaceStyles } from "@styles/Workspace.style";
import {useAuthenticationClient} from "@contexts/AuthenticationClient.context";
import {pipe} from "fp-ts/lib/function";
import * as T from "fp-ts/lib/Task";
import * as TE from "fp-ts/lib/TaskEither";
import {AuthenticationError} from "@model/clients/AuthenticationApi";
import {handleAuthenticationErrorIo} from "@utils/errorHandlers/errorHandlers";
import * as IO from "fp-ts/IO";
import {useIoBasedLoadingSetters} from "@contexts/Loading.context";
import HomePage from "@pages/workspace/Home.page";
import {Ok} from "@model/Ok";

const Drawer = createDrawerNavigator<WorkspaceRoutesParamList>();

type WorkspaceNavigatorProps = {
    navigation: StackNavigationProp<AppStackParamList, typeof AppRoutes.WORKSPACE>;
};

const WorkspaceNavigator: React.FC<WorkspaceNavigatorProps> = ({ navigation }) => {
    const {authenticationClient} = useAuthenticationClient();
    const {triggerLoadingState, triggerSuccessState, triggerErrorState} = useIoBasedLoadingSetters();
    const navigateToLogin =
        T.fromIO(() => navigation.navigate(AppRoutes.AUTH, {
            screen: AuthenticationRoutes.LOGIN,
        }));

    const handleLogout = useMemo(
        (): T.Task<Ok> =>
            pipe(
                T.fromIO(triggerLoadingState),
                T.flatMap(() =>
                    pipe(
                        authenticationClient.logout(),
                        TE.fold(
                            (error: AuthenticationError) =>
                                T.fromIO(handleAuthenticationErrorIo(error, triggerErrorState)),
                            () =>
                                T.fromIO(
                                    pipe(
                                        navigateToLogin,
                                        IO.flatMap(() => triggerSuccessState)
                                    )
                                )
                        )
                    )
                )
            ),
        [triggerLoadingState, authenticationClient, handleAuthenticationErrorIo, triggerErrorState, navigation]
    );


    return (
        <PaymentClientProvider>
            <AccountClientProvider>
                <Drawer.Navigator
                    screenOptions={{
                        headerTintColor: '#fff',
                        drawerStyle: WorkspaceStyles.drawerStyle,
                        drawerActiveTintColor: "#fff",
                        drawerInactiveTintColor: "#fff",
                    }}
                    drawerContent={(props: DrawerContentComponentProps) => (
                        <DrawerContentScrollView {...props}>
                            <DrawerItem
                                style={[
                                    props.state.index === 0 ? WorkspaceStyles.activeDrawerItem : WorkspaceStyles.drawerItem,
                                    { marginTop: 20 },
                                ]}
                                labelStyle={WorkspaceStyles.labelStyle}
                                label="Home"
                                icon={() => <MaterialIcons name="home" size={20} color="#fff" />}
                                onPress={() =>
                                    navigation.navigate(AppRoutes.WORKSPACE, {
                                        screen: WorkspaceRoutes.HOME,
                                    })
                                }
                            />
                            <DrawerItem
                                style={props.state.index === 1 ? WorkspaceStyles.activeDrawerItem : WorkspaceStyles.drawerItem}
                                labelStyle={WorkspaceStyles.labelStyle}
                                label="Document Selection"
                                icon={() => <MaterialIcons name="article" size={20} color="#fff" />}
                                onPress={() =>
                                    navigation.navigate(AppRoutes.WORKSPACE, {
                                        screen: WorkspaceRoutes.DOCUMENT_SELECTION,
                                    })
                                }
                            />
                            <DrawerItem
                                style={props.state.index === 2 ? WorkspaceStyles.activeDrawerItem : WorkspaceStyles.drawerItem}
                                labelStyle={WorkspaceStyles.labelStyle}
                                label="Create New Document"
                                icon={() => <MaterialIcons name="description" size={20} color="#fff" />}
                                onPress={() =>
                                    navigation.navigate(AppRoutes.WORKSPACE, {
                                        screen: WorkspaceRoutes.DOCUMENT_CREATION,
                                    })
                                }
                            />
                            <DrawerItem
                                style={props.state.index === 3 ? WorkspaceStyles.activeDrawerItem : WorkspaceStyles.drawerItem}
                                labelStyle={WorkspaceStyles.labelStyle}
                                label="Submit Feedback"
                                icon={() => <MaterialIcons name="feedback" size={20} color="#fff" />}
                                onPress={() =>
                                    navigation.navigate(AppRoutes.WORKSPACE, {
                                        screen: WorkspaceRoutes.FEEDBACK_SUBMISSION,
                                    })
                                }
                            />
                            <DrawerItem
                                style={props.state.index === 4 ? WorkspaceStyles.activeDrawerItem : WorkspaceStyles.drawerItem}
                                labelStyle={WorkspaceStyles.labelStyle}
                                label="Account Management"
                                icon={() => <MaterialIcons name="account-circle" size={20} color="#fff" />}
                                onPress={() =>
                                    navigation.navigate(AppRoutes.WORKSPACE, {
                                        screen: WorkspaceRoutes.ACCOUNT_MANAGEMENT,
                                    })
                                }
                            />
                            {enabledFeatures[FeaturesEnum.DrawerSignOutButton] && (
                                <DrawerItem
                                    style={WorkspaceStyles.drawerItem}
                                    labelStyle={WorkspaceStyles.labelStyle}
                                    label="Logout"
                                    icon={() => <MaterialIcons name="logout" size={20} color="#fff" />}
                                    onPress= {handleLogout}
                                />
                            )}
                        </DrawerContentScrollView>
                    )}
                >
                    <Drawer.Screen
                        name={WorkspaceRoutes.HOME}
                        component={HomePage}
                        options={{
                            headerShown: true,
                            headerStyle: WorkspaceStyles.headerStyle,
                            headerTitleStyle: WorkspaceStyles.headerTitleStyle,
                        }}
                    />
                    <Drawer.Screen
                        name={WorkspaceRoutes.DOCUMENT_SELECTION}
                        component={withAppNavigator(DocumentSelectionPage, navigation)}
                        options={{
                            headerShown: true,
                            headerStyle: WorkspaceStyles.headerStyle,
                            headerTitleStyle: WorkspaceStyles.headerTitleStyle,
                        }}
                    />
                    <Drawer.Screen
                        name={WorkspaceRoutes.DOCUMENT_CREATION}
                        component={withAppNavigator(DocumentCreationPage, navigation)}
                        options={{
                            headerShown: true,
                            headerStyle: WorkspaceStyles.headerStyle,
                            headerTitleStyle: WorkspaceStyles.headerTitleStyle,
                        }}
                    />

                    <Drawer.Screen
                        name={WorkspaceRoutes.FEEDBACK_SUBMISSION}
                        component={withAppNavigator(FeedbackSubmissionPage, navigation)}
                        options={{
                            headerShown: true,
                            headerStyle: WorkspaceStyles.headerStyle,
                            headerTitleStyle: WorkspaceStyles.headerTitleStyle,
                        }}
                    />

                    <Drawer.Screen
                        name={WorkspaceRoutes.ACCOUNT_MANAGEMENT}
                        component={withAppNavigator(AccountManagementPage, navigation)}
                        options={{
                            headerShown: true,
                            headerStyle: WorkspaceStyles.headerStyle,
                            headerTitleStyle: WorkspaceStyles.headerTitleStyle,
                        }}
                    />
                </Drawer.Navigator>
            </AccountClientProvider>
        </PaymentClientProvider>
    );
};

export default WorkspaceNavigator;
