import { sexyStyles } from '@src/styles/OuroborosSexyTheme.style';
import React, { useRef, useEffect } from 'react';
import { Animated, View, StyleSheet, Easing } from 'react-native';

const OuroborosSpinnerComponent: React.FC = () => {
    const spinValue = useRef(new Animated.Value(0)).current;

    useEffect(() => {
      const spinAnimation = Animated.timing(spinValue, {
        toValue: 1,
        duration: 1000, // Duration for one complete rotation
        easing: Easing.linear,
        useNativeDriver: true,
      });
  
      // Function to loop the animation
      const startSpinning = () => {
        spinValue.setValue(0); // Reset spinValue to 0 before starting the animation
        spinAnimation.start(() => startSpinning()); // Start animation and loop it
      };
  
      startSpinning(); // Kick off the first spin
  
      // Cleanup the animation on component unmount
      return () => spinAnimation.stop();
    }, [spinValue]);
  
    const spin = spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg'],
    });

  return (
    <View style={sexyStyles.container}>
      <Animated.Image
// eslint-disable-next-line @typescript-eslint/no-require-imports
          source={require('@public/ouroboros_logo_flat.png')}
        style={[styles.logo, { transform: [{ rotate: spin }] }]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  logo: {
    width: 100, // Adjust according to your logo size
    height: 100, // Adjust according to your logo size
  },
});

export default OuroborosSpinnerComponent;