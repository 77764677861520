// @contexts/Document.context.tsx

import React, { createContext, useContext, ReactNode, useMemo, useCallback } from 'react';
import { UserDocument, Section, SectionID, DocumentID } from '@model/documents/core';
import * as IO from 'fp-ts/lib/IO'; // Import IO utilities
import { useIoState } from '@utils/useIoState';
import {Ok} from "@model/Ok";

type DocumentContextType = {
    document: UserDocument | null;
    activeSectionID: SectionID | null;
    setDocumentIo: (doc: UserDocument) => IO.IO<Ok>;
    setActiveSectionIDIo: (id: SectionID) => IO.IO<Ok>;
    updateSectionIo: (updatedSection: Section) => IO.IO<Ok>;
    appendSectionIo: (newSection: Section) => IO.IO<Ok>; // New append function
};

type NonNullDocumentContextType = {
    document: UserDocument;
    activeSectionID: SectionID | null;
    setDocumentIo: (doc: UserDocument) => IO.IO<Ok>;
    setActiveSectionIDIo: (id: SectionID) => IO.IO<Ok>;
    updateSectionIo: (updatedSection: Section) => IO.IO<Ok>;
    appendSectionIo: (newSection: Section) => IO.IO<Ok>; // New append function
};

const DocumentContext = createContext<DocumentContextType | undefined>(undefined);

type DocumentProviderProps = {
    children: ReactNode;
};

export const DocumentProvider: React.FC<DocumentProviderProps> = ({ children }) => {
    const [document, setDocumentIo] = useIoState<UserDocument | null>(null);
    const [activeSectionID, setActiveSectionIDIo] = useIoState<SectionID | null>(null);

    // Update section with IO
    const updateSectionIo = useCallback(
        (updatedSection: Section): IO.IO<Ok> => {
            if (!document) return () => Ok;

            const updatedContents = document.document_contents.map((section) =>
                section.metadata.index === updatedSection.metadata.index
                    ? updatedSection
                    : section
            );

            const newDocument = { ...document, document_contents: updatedContents };

            return setDocumentIo(newDocument);
        },
        [document, setDocumentIo]
    );

    // Append a new section to the document
    const appendSectionIo = useCallback(
        (newSection: Section): IO.IO<Ok> => {
            if (!document) return () => Ok;

            const updatedDocument = {
                ...document,
                document_contents: [...document.document_contents, newSection], // Append the new section
            };

            return setDocumentIo(updatedDocument);
        },
        [document, setDocumentIo]
    );

    // Memoize the context value to avoid unnecessary re-renders
    const contextValue = useMemo(
        () => ({
            document,
            activeSectionID,
            setDocumentIo,
            setActiveSectionIDIo,
            updateSectionIo,
            appendSectionIo, // Add appendSectionIo to context value
        }),
        [document, activeSectionID, setDocumentIo, setActiveSectionIDIo, updateSectionIo, appendSectionIo]
    );

    return <DocumentContext.Provider value={contextValue}>{children}</DocumentContext.Provider>;
};

export const useDocumentContext = (): DocumentContextType => {
    const context = useContext(DocumentContext);
    if (!context) {
        throw new Error('useDocumentContext must be used within a DocumentProvider');
    }
    return context;
};

// This hook guarantees that the document is non-null
export const useNonNullDocumentContext = (): NonNullDocumentContextType => {
    const context = useDocumentContext();
    if (!context.document) {
        throw new Error('Document is not set in DocumentContext');
    }

    return {
        ...context,
        document: context.document as UserDocument, // Assert that document is non-null
    };
};

// Helper hook to get the document ID safely
export const useDocumentID = (): DocumentID => {
    const { document } = useNonNullDocumentContext();
    return document.id;
};
