import React, { useState, useRef } from "react"; 
import {
  View,
  Text,
  TextInput,
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { FeedbackStyles as styles } from "@styles/Feedback.style";
import { Section } from "@model/documents/core";
import * as T from "fp-ts/lib/Task";
import { MaterialIcons } from "@expo/vector-icons";
import { NativeSyntheticEvent, TextInputContentSizeChangeEventData } from 'react-native';
import {Ok} from "@model/Ok";

type RefineComponentProps = {
  onSubmitTask: (instructions: string) => T.Task<Ok>;
  mostRecentSection: Section | null;
};

const RefineComponent: React.FC<RefineComponentProps> = ({
  onSubmitTask,
  mostRecentSection,
}) => {
  const [feedback, setFeedbackImpure] = useState(""); // useState with renamed setter
  const [inputHeight, setInputHeight] = useState(100);
  const inputRef = useRef<TextInput>(null);

  const handleContentSizeChange = (
      e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>
  ) => {
    const newHeight = e.nativeEvent.contentSize.height;
    if (newHeight > inputHeight && newHeight <= 150) {
      setInputHeight(newHeight);
    } else if (newHeight < 50) {
      setInputHeight(35);
    }
  };

  if (mostRecentSection) {
    return (
      <View style={styles.container}>
        <Text style={styles.header}>AI Refine</Text>
        <Text style={styles.subheader}>{mostRecentSection.metadata.name}:</Text>
        <ScrollView style={styles.sectionContainer}>
          <Text style={styles.section}>
            {mostRecentSection.section_contents}
          </Text>
        </ScrollView>
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
          <View style={{ flex: 1, borderColor: '#ccc', borderWidth: 1, borderRadius: 8 }}>
            <TextInput
              ref={inputRef}
              style={[styles.input2, { height: inputHeight }]}
              value={feedback}
              onChangeText={setFeedbackImpure} // Direct use of the useState setter
              placeholder="Enter your feedback here"
              placeholderTextColor={'gray'}
              multiline={true}
              onContentSizeChange={handleContentSizeChange}
              scrollEnabled={true}
              blurOnSubmit={false} // Prevent blur on submit, can be helpful to preserve focus
            />
          </View>
          <TouchableOpacity
            style={styles.button}
            onPress={() => onSubmitTask(feedback)()} // On Submit
          >
            <MaterialIcons name="send" size={24} color="white" />
          </TouchableOpacity>
        </View>
      </View>
    );
  } else {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#0000ff" />
        <Text>Loading ...</Text>
      </View>
    );
  }
};

export default RefineComponent;
