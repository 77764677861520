// @utils/Effect.ts

import * as IO from "fp-ts/lib/IO";
import { Ok } from "@model/Ok";
import { pipe } from "fp-ts/lib/function";

export const Effect = {
    /**
     * Wraps a synchronous side effect into an IO<Ok>.
     * Ensures the effect returns a typed Ok sentinel.
     *
     * @example
     * const updateUI = Effect.fromVoid(() => setState(...));
     */
    // eslint-disable-next-line no-void-effect/no-void-effect
    asOk: (f: IO.IO<void>): IO.IO<Ok> =>
        pipe(f, IO.as(Ok)),
};
