// @services/ouroboros/DefaultFeedbackClient.ts

import { FeedbackClient } from '@services/clients/FeedbackClient';
import { SubmitFeedbackRequest, SubmitFeedbackResponse, FeedbackError } from '@model/clients/FeedbackApi';
import API_URL from '@utils/config';
import * as t from 'io-ts';
import { v7 as uuidv7 } from 'uuid';
import { TaskEither, tryCatch, left, chain} from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';

export class DefaultFeedbackClient implements FeedbackClient {
    constructor() {
        // No dependencies needed
    }

    // Generate a new trace ID for tracking the request
    private generateTraceId(): string {
        return uuidv7();
    }

    // Helper method to fetch with trace ID
    private fetchWithTrace(
        url: string,
        options: RequestInit
    ): TaskEither<FeedbackError, Response> {
        const traceId = this.generateTraceId();
        options.headers = {
            ...options.headers,
            'X-Trace-ID': traceId,
        };

        options.credentials = 'include'; // Ensures credentials are included

        return tryCatch(
            async () => {
                const response = await fetch(url, options);

                if (!response.ok) {
                    throw {
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as FeedbackError;
                }

                return response;
            },
            (e) => {
                if (e instanceof TypeError) {
                    return {
                        type: 'NetworkError',
                        message: 'Failed to connect to the server. Please check your internet connection.',
                    } as FeedbackError;
                }
                return e as FeedbackError;
            }
        );
    }

    // Helper method to validate the response against the given io-ts validator
    private validateResponse<T>(
        response: Response,
        validator: t.Type<T>
    ): TaskEither<FeedbackError, T> {
        return tryCatch(
            async () => {
                const data = await response.json();
                const decoded = validator.decode(data);
                if (decoded._tag === 'Right') {
                    return decoded.right;
                } else {
                    throw new Error('Validation failed');
                }
            },
            () =>
                ({
                    type: 'ValidationError',
                    message: 'Response validation failed',
                } as FeedbackError)
        );
    }

    // Implementation of the submitFeedback method
    submitFeedback(
        request: SubmitFeedbackRequest
    ): TaskEither<FeedbackError, SubmitFeedbackResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/feedback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request),
            }),
            chain((response) =>
                response.ok
                    ? this.validateResponse<SubmitFeedbackResponse>(
                        response,
                        SubmitFeedbackResponse
                    )
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as FeedbackError)
            )
        );
    }
}
