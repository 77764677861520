import React, { useCallback } from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { DocumentRoutes } from "@navigation/RouteEnums";
import { DocumentRoutesParamList } from "@navigation/NavigationRouteParameters";
import SectionList from "@src/components/document/review/SectionList";
import NewSectionModal from "@src/components/document/review/NewSectionModal";
import { useContentClient } from "@contexts/ContentClient.context";
import { useNonNullDocumentContext } from "@contexts/Document.context";
import { useLifecycleClient } from "@contexts/LifecycleClient.context";
import { useIoState } from "@src/utils/useIoState";
import { useIoBasedLoadingSetters } from "@contexts/Loading.context";
import { pipe } from "fp-ts/lib/function";
import { fold } from "fp-ts/lib/TaskEither";
import * as T from "fp-ts/lib/Task";
import * as IO from "fp-ts/lib/IO";
import * as TE from "fp-ts/lib/TaskEither";
import { handleContentErrorIo } from "@utils/errorHandlers/contentErrorHandler";
import { handleLifecycleErrorIo } from "@utils/errorHandlers/lifecycleErrorHandler";
import Toast from "react-native-toast-message";
import { MaterialIcons } from "@expo/vector-icons";
import { WritingStyles as styles } from "@src/styles/Review.style";
import { UploadSupplementalDocumentRequest } from "@src/model/clients/ContentApi";
import { Ok } from "@model/Ok";
import { DocumentID } from "@model/documents/core";
import { Section } from "@src/model/documents/core";
import { LifecycleError } from "@model/clients/LifecycleApi";
import { useFilePicker } from "@src/hooks/useFilePicker";
import { Text, TouchableOpacity, View } from "react-native";
import { handleUploadErrorIo } from "@src/utils/errorHandlers/uploadErrorHandler";
import {Effect} from "@utils/Effect";

type ReviewProps = {
  navigation: StackNavigationProp<
    DocumentRoutesParamList,
    typeof DocumentRoutes.REVIEW
  >;
};

const ReviewPage: React.FC<ReviewProps> = ({ navigation }) => {
  const { contentClient } = useContentClient();
  const { lifecycleClient } = useLifecycleClient();
  const {
    document: userDocument,
    activeSectionID,
    appendSectionIo,
  } = useNonNullDocumentContext();
  const { triggerLoadingState, triggerSuccessState, triggerErrorState } =
    useIoBasedLoadingSetters();

  // State for Modal
  const [isModalVisible, setIsModalVisible] = useIoState(false);
  const [newSectionTitle, setNewSectionTitle] = useIoState("");
  const [sectionTitleError, setSectionTitleError] = useIoState<string | null>(
    null
  );

  // Navigate functions
  const navigateToPromptIo =
    (probingQuestions: string): IO.IO<Ok> =>
    Effect.asOk(() =>
      navigation.navigate(DocumentRoutes.PROMPT, {
        probingQuestion: probingQuestions,
      }));

  const navigateToRefineIo: IO.IO<Ok> = Effect.asOk(() =>
    navigation.navigate(DocumentRoutes.REFINE));
  const navigateToFinalEditsIo: IO.IO<Ok> = Effect.asOk(() =>
    navigation.navigate(DocumentRoutes.FINAL_EDITS));
  const navigateToReviewIo: IO.IO<Ok> = Effect.asOk(() =>
    navigation.navigate(DocumentRoutes.REVIEW)
  );

  // Handle Draft - Generate Section Questions
  const handleDraft: T.Task<Ok> = pipe(
    T.fromIO(triggerLoadingState),
    T.chain(() =>
      pipe(
        contentClient.generateSectionQuestions(userDocument.id, {
          section_id: activeSectionID!,
        }),
        fold(
          (error) => T.fromIO(handleContentErrorIo(error, triggerErrorState)),
          (response) =>
            T.fromIO(
              pipe(
                navigateToPromptIo(response.probing_questions),
                IO.flatMap(() => triggerSuccessState)
              )
            )
        )
      )
    )
  );

  const { selectFile } = useFilePicker();

  const handleUpload: T.Task<Ok> = pipe(
    // Trigger loading state first.
    T.fromIO(triggerLoadingState),
    T.chain(() =>
      pipe(
        // Select a file and convert the resulting Task into a TaskEither.
        TE.fromTaskOption(() => "No file selected")(selectFile()),
        // Validate that we got a file and that activeSectionID is not null.
        TE.chain((file: File) =>
          pipe(
            // Create a tuple of [file, activeSectionID]
            TE.of([file, activeSectionID] as const),
            // Check that both elements of the tuple are non-null.
            TE.filterOrElse(
              ([f, sectionID]) => f != null && sectionID != null,
              () => "No file selected or missing section ID"
            ),
            // Now that both are known to be non-null, destructure the tuple.
            TE.map(([file, sectionID]) => ({
              file,
              supplementalDocumentRequest: {
                fileName: file.name,
                file,
                relation: "Example of supplemental document",
                section_id: sectionID as NonNullable<typeof activeSectionID>,
              },
            }))
          )
        ),
        // Check the section ID and, if valid, perform the upload.
        TE.chain(({ supplementalDocumentRequest }) =>
          uploadSupplementalDocument(
            userDocument.id,
            supplementalDocumentRequest
          )
        ),
        // Handle the outcome of the upload.
        TE.fold(
          // On error, run an IO-based error handler.
          (error) =>
            pipe(
              T.fromIO(handleUploadErrorIo(error, triggerErrorState)),
              T.map(() => Ok)
            ),
          // On success, show a Toast and trigger a success state.
          () =>
            T.fromIO(
              pipe(
                () =>
                  Toast.show({
                    type: "success",
                    position: "bottom",
                    text1: "Supplemental document uploaded successfully!",
                    visibilityTime: 3000,
                    bottomOffset: 20,
                  }),
                IO.flatMap(() => triggerSuccessState),
                IO.map(() => Ok)
              )
            )
        )
      )
    )
  );

  const uploadSupplementalDocument = (
    documentId: string,
    request: UploadSupplementalDocumentRequest
  ): TE.TaskEither<string, Ok> =>
    pipe(
      TE.tryCatch(
        async () => {
          console.log(`Uploading supplemental document: ${request.fileName}`);
          await contentClient.uploadSupplementalDocument(documentId, request);
        },
        (error) => `Error uploading supplemental document: ${error}`
      ),
      TE.map(() => Ok)
    );

  // Handle Add New Section
  const handleAddNewSection = (sectionTitle: string): T.Task<Ok> =>
    pipe(
      T.fromIO(triggerLoadingState),
      T.flatMap(() =>
        pipe(
          contentClient.appendSection(userDocument.id, {
            section_title: sectionTitle,
          }),
          fold(
            (error) => T.fromIO(handleContentErrorIo(error, triggerErrorState)),
            (response) => T.fromIO(handleSuccessIo(response.new_section))
          )
        )
      )
    );

  const handleSuccessIo = (newSection: Section): IO.IO<Ok> =>
    pipe(
      appendSectionIo(newSection),
      IO.flatMap(() => triggerSuccessState),
      IO.flatMap(() => navigateToReviewIo)
    );

  const openModal: IO.IO<Ok> = pipe(
    triggerLoadingState,
    IO.chain(() => setIsModalVisible(true)),
    IO.chain(() => setNewSectionTitle("")),
    IO.chain(() => setSectionTitleError(null)),
    IO.chain(() => triggerSuccessState)
  );

  const closeModal: IO.IO<Ok> = pipe(
    triggerLoadingState,
    IO.chain(() => setIsModalVisible(false)),
    IO.chain(() => setSectionTitleError(null)),
    IO.chain(() => triggerSuccessState)
  );

  const handleSubmitNewSection: T.Task<Ok> = pipe(
    T.fromIO(() => newSectionTitle.trim() === ""),
    T.chain((isEmpty) =>
      isEmpty
        ? T.fromIO(setSectionTitleError("Section title is required"))
        : pipe(
            handleAddNewSection(newSectionTitle),
            T.chain(() => T.fromIO(closeModal))
          )
    )
  );

  // Handle Document Download
  const triggerUserDownload = useCallback(
    (blob: Blob, title: string): IO.IO<Ok> =>
      Effect.asOk(() => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${title}.md`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }),
    []
  );

  const handleDownload = useCallback(
    (id: DocumentID, title: string): T.Task<Ok> =>
      pipe(
        T.fromIO(triggerLoadingState),
        T.flatMap(() =>
          pipe(
            lifecycleClient.downloadDocumentAsBlob(id),
            TE.fold(
              (error: LifecycleError) =>
                T.fromIO(handleLifecycleErrorIo(error, triggerErrorState)),
              (blob) =>
                pipe(
                  T.fromIO(triggerUserDownload(blob, title)),
                  T.flatMap(() => T.fromIO(triggerSuccessState))
                )
            )
          )
        )
      ),
    [
      triggerLoadingState,
      lifecycleClient,
      handleLifecycleErrorIo,
      triggerSuccessState,
      triggerErrorState,
      triggerUserDownload,
    ]
  );

  return (
    <>
      <View style={styles.buttonContainer}>
        <Text style={{ fontSize: 16, color: "#000" }}>
          <Text style={{ fontWeight: "bold" }}>Note:-</Text> Click to see
          options
        </Text>
        <View style={styles.buttonContainerSecond}>
          <TouchableOpacity style={[styles.button]} onPress={openModal}>
            <Text style={styles.buttonText}>Append New Section</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.actionButton}
            onPress={() =>
              handleDownload(userDocument.id, userDocument!.name)()
            }
          >
            <MaterialIcons
              name="download"
              size={24}
              style={styles.icon}
              color={"#fff"}
            />
            <Text style={styles.actionButtonText}>Download</Text>
          </TouchableOpacity>
        </View>
      </View>
      <SectionList
        handleDraft={handleDraft}
        handleRefine={navigateToRefineIo}
        handleEdits={navigateToFinalEditsIo}
        handleUpload={handleUpload}
      />
      <NewSectionModal
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        newSectionTitle={newSectionTitle}
        setNewSectionTitle={setNewSectionTitle}
        sectionTitleError={sectionTitleError}
        handleSubmitNewSection={handleSubmitNewSection}
      />
    </>
  );
};

export default ReviewPage;
