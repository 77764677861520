// @services/ouroboros/DefaultAuthenticationClient.ts
import { AuthenticationClient } from '@services/clients/AuthenticationClient';
import {
    CreateAccountRequest, JwtResponse,
    LoginRequest,
    SubscriptionStatus,
    SubscriptionStatusResponse,
} from '@model/clients/AuthenticationApi';
import API_URL from '@utils/config';
import { v7 as uuidv7 } from 'uuid';
import {TaskEither, tryCatch, left, right, chain} from 'fp-ts/lib/TaskEither';
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function';
import { AuthenticationError } from '@model/clients/AuthenticationApi';
import * as t from 'io-ts'; // io-ts for response validation
import { fold } from 'fp-ts/lib/Either';

export class DefaultAuthenticationClient implements AuthenticationClient {
    constructor() {
        // No dependencies
    }

    private generateTraceId(): string {
        return uuidv7();
    }

    private fetchWithTrace(
        url: string,
        options: RequestInit
    ): TaskEither<AuthenticationError, Response> {
        const traceId = this.generateTraceId();
        options.headers = {
            ...options.headers,
            'X-Trace-ID': traceId,
        };
        options.credentials = 'include'; // Ensure cookies (including JWT) are included in requests

        return tryCatch(
            async () => await fetch(url, options),
            (_error) =>
                ({
                    type: 'NetworkError',
                    message: 'Failed to connect to the server. Please check your internet connection.',
                } as AuthenticationError)
        );
    }

    // Helper method to parse the response safely
    private parseResponse<A>(codec: t.Type<A>, response: Response): TaskEither<AuthenticationError, A> {
        return pipe(
            tryCatch(
                async () => {
                    const json = await response.json();
                    return json;
                },
                () =>
                    ({
                        type: 'ValidationError',
                        message: 'Failed to parse the server response.',
                    } as AuthenticationError)
            ),
            chain((json) =>
                pipe(
                    codec.decode(json),
                    fold(
                        () =>
                            left({
                                type: 'ValidationError',
                                message: 'Invalid response format.',
                            } as AuthenticationError),
                        right
                    )
                )
            )
        );
    }

    createAccount(request: CreateAccountRequest): TaskEither<AuthenticationError, void> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/auth/create-account`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request),
            }),
            chain((response) => {
                if (response.status === 409) {
                    return left({
                        type: 'ExistingAccountError',
                        message: 'An account with this email already exists. Please use a different email or log in.',
                    } as AuthenticationError);
                } else if (response.status === 401) {
                    return left({
                        type: 'Unauthorized',
                        message: 'The credentials provided were invalid. Please check your email and password, then try again.',
                    } as AuthenticationError);
                } else if (!response.ok) {
                    return left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as AuthenticationError);
                }
                return right(undefined);
            })
        );
    }

    login(request: LoginRequest): TaskEither<AuthenticationError, SubscriptionStatus> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/auth/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request),
            }),
            // Filter the response based on its status code using `filterOrElse`
            TE.filterOrElse(
                (response) => response.ok, // predicate: response is OK
                (response) =>
                    response.status === 401
                        ? ({
                            type: 'Unauthorized',
                            message: 'Incorrect username or password. Please try again.',
                        } as AuthenticationError)
                        : ({
                            type: 'ServerError',
                            message: `Server responded with an error: ${response.status}`,
                            statusCode: response.status,
                        } as AuthenticationError)
            ),
            // Continue to parse the valid response
            chain((response) => this.parseResponse(SubscriptionStatusResponse, response)),
            TE.map((typedResponse: SubscriptionStatusResponse) => typedResponse.subscription_status)
        );
    }
    refreshJwtToken(): TaskEither<AuthenticationError, JwtResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/auth/refresh-jwt`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            }),
            // Filter the response to check if it's successful
            TE.filterOrElse(
                (response) => response.ok,
                (response) =>
                    response.status === 401
                        ? ({
                            type: 'Unauthorized',
                            message: 'Failed to refresh JWT. User is unauthorized.',
                        } as AuthenticationError)
                        : ({
                            type: 'ServerError',
                            message: `Server responded with an error: ${response.status}`,
                            statusCode: response.status,
                        } as AuthenticationError)
            ),
            // Continue to parse the response using the appropriate codec
            chain((response) => this.parseResponse(SubscriptionStatusResponse, response)),
            TE.map((typedResponse: SubscriptionStatusResponse) => ({
                status: 'authorized',
                subscription_status: typedResponse.subscription_status,
            } as JwtResponse)),
            TE.orElse(() => right({ status: 'unauthorized', subscription_status: 'not-found'} as JwtResponse)) // In case of a parsing error, default to unauthorized
        );
    }

    logout(): TaskEither<AuthenticationError, void> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/auth/logout`, {
                method: 'POST',
            }),
            chain((response) => {
                if (!response.ok) {
                    return left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as AuthenticationError);
                }
                return right(undefined);
            })
        );
    }
}
