// @model/clients/ContentApi.ts

import * as t from 'io-ts';
import { SectionID, Section } from '@model/documents/core';
import { Markdown } from '@model/markdown';

// Generate Section Questions Request & Response
export const GenerateSectionQuestionsRequest = t.type({
    section_id: SectionID,
});
export type GenerateSectionQuestionsRequest = t.TypeOf<typeof GenerateSectionQuestionsRequest>;

export const GenerateSectionQuestionsResponse = t.type({
    probing_questions: Markdown,
});
export type GenerateSectionQuestionsResponse = t.TypeOf<typeof GenerateSectionQuestionsResponse>;

// Answer Section Questions Request & Response
export const AnswerSectionQuestionsRequest = t.type({
    section_id: SectionID,
    answers: Markdown,
});
export type AnswerSectionQuestionsRequest = t.TypeOf<typeof AnswerSectionQuestionsRequest>;

export const AnswerSectionQuestionsResponse = t.type({
    updated_section: Section,
});
export type AnswerSectionQuestionsResponse = t.TypeOf<typeof AnswerSectionQuestionsResponse>;

export const GenerateDocumentQuestionsResponse = t.type({
    probing_questions: Markdown,
});
export type GenerateDocumentQuestionsResponse = t.TypeOf<typeof GenerateDocumentQuestionsResponse>;

// Answer Document Questions Request & Response
export const AnswerDocumentQuestionsRequest = t.type({
    answers: Markdown,
});
export type AnswerDocumentQuestionsRequest = t.TypeOf<typeof AnswerDocumentQuestionsRequest>;


interface UserSectionReplacementBrand {
    readonly UserSectionReplacement: unique symbol; // Branding marker
}

// Create the branded type manually by casting the string to unknown first
export const UserSectionReplacement = t.string as unknown as t.BrandC<t.StringC, UserSectionReplacementBrand>;

// Export the branded type for usage
export type UserSectionReplacement = t.TypeOf<typeof UserSectionReplacement>;

// Replace Section Request & Response
export const ReplaceSectionRequest = t.type({
    section_id: SectionID,
    edits: UserSectionReplacement,
});
export type ReplaceSectionRequest = t.TypeOf<typeof ReplaceSectionRequest>;

export const ReplaceSectionResponse = t.type({
    updated_section: Section,
});
export type ReplaceSectionResponse = t.TypeOf<typeof ReplaceSectionResponse>;

// Feedback Message and Refine
export const FeedbackMessage = t.string;
export type FeedbackMessage = t.TypeOf<typeof FeedbackMessage>;

export const RefineRequest = t.type({
    feedback: FeedbackMessage,
    section_id: SectionID, // Include section_id to identify the section being refined
});
export type RefineRequest = t.TypeOf<typeof RefineRequest>;

export const RefineResponse = t.type({
    updated_section: Section, // Include the updated section
});
export type RefineResponse = t.TypeOf<typeof RefineResponse>;

export const AppendSectionRequest = t.type({
    section_title: t.string,
});
export type AppendSectionRequest = t.TypeOf<typeof AppendSectionRequest>;

export const AppendSectionResponse = t.type({
    new_section: Section,
});
export type AppendSectionResponse = t.TypeOf<typeof AppendSectionResponse>;

export const FileCodec = new t.Type<File, File, unknown>(
    "FileCodec",
    (u): u is File => u instanceof File,
    (u, c) => (u instanceof File ? t.success(u) : t.failure(u, c)),
    t.identity
);

// New type for uploading supplemental documents as part of the Content API.
// This request carries a file (as a Blob), the file's name, and a relation descriptor.
export const UploadSupplementalDocumentRequest = t.type({
    fileName: t.string,
    // Using t.unknown to represent the file blob since Blob doesn't have a built-in io-ts codec.
    file:  FileCodec,
    // A typed string explaining the relationship of the uploaded document (e.g., "this is an example of what I'm looking for" or "Here's some reference data you'll need")
    relation: t.string,
    section_id: SectionID, // Include section_id to identify the section the supplemental data should be associated with
});
export type UploadSupplementalDocumentRequest = t.TypeOf<typeof UploadSupplementalDocumentRequest>;


// Define ContentError type
export type ContentError =
    | { type: 'NetworkError'; message: string }
    | { type: 'ServerError'; message: string; statusCode: number }
    | { type: 'ValidationError'; message: string }
    | { type: 'UnknownError'; message: string };
