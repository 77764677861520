// @components/workspace/creation-flow/DocumentIntentions.tsx
import React from "react";
import { View, Text, TextInput } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { DocumentCreationStyles as styles } from "@styles/DocumentCreation.style";
import * as IO from "fp-ts/lib/IO";
import * as T from "fp-ts/lib/Task";
import { Ok } from "@model/Ok";
import * as O from "fp-ts/lib/Option";

export type NextAction = {
    action: T.Task<Ok>;
    label: string;
};

type DocumentIntentionsProps = {
    styleText: O.Option<string>;
    onStyleChange: (text: string) => IO.IO<Ok>;
    nextAction: NextAction;
    onPrevious: IO.IO<Ok>;
};

const DocumentIntentionsView: React.FC<DocumentIntentionsProps> = ({
                                                                       styleText,
                                                                       onStyleChange,
                                                                       nextAction,
                                                                       onPrevious,
                                                                   }) => (
    <View style={styles.containerDoc}>
        <Text style={styles.heading}>Your Intentions</Text>
        <TextInput
            style={styles.multiline}
            placeholder="Explain your intentions for the document and any style or tone guidelines. You can also provide examples or drafts."
            value={O.getOrElse<string>(() => "")(styleText)}
            placeholderTextColor={"gray"}
            onChangeText={(newText) => onStyleChange(newText)()}
            multiline={true}
        />
        <View style={styles.viewStyle}>
            <TouchableOpacity style={styles.button2} onPress={onPrevious}>
                <Text style={styles.buttonText}>Back</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button2} onPress={() => nextAction.action()}>
                <Text style={styles.buttonText}>{nextAction.label}</Text>
            </TouchableOpacity>
        </View>
    </View>
);

export default DocumentIntentionsView;
