// @components/workspace/Home.tsx

import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { HomeStyles as styles } from "@styles/Home.style";
import * as IO from "fp-ts/lib/IO";
import {Ok} from "@model/Ok"; // IO utilities for handling impure effects

type HomeComponentProps = {
    navigateToDocumentCreation: IO.IO<Ok>; // Navigation prop as an IO action
};

const HomeComponent: React.FC<HomeComponentProps> = ({
                                                         navigateToDocumentCreation,
                                                     }) => {
    return (
        <View style={styles.container}>
            <Text style={styles.greetingText}>Welcome to the Ouroborite Writing Assistant, an Ouroboros AI product.</Text>
            <Text style={styles.subtext}>Create your first document to get started.</Text>
            <TouchableOpacity
                style={styles.getStartedButton}
                onPress={navigateToDocumentCreation} // Executing the IO action for navigation
            >
                <Text style={styles.buttonText}>Get Started</Text>
            </TouchableOpacity>
        </View>
    );
};

export default HomeComponent;
