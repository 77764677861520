// @pages/feedback/FeedbackSubmission.page.tsx

import React, { useMemo } from 'react';
import FeedbackSubmissionComponent from '@src/components/workspace/FeedbackSubmission';
import { useIoBasedLoadingSetters } from '@contexts/Loading.context'; // Use IO-based loading context setters
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';
import * as IO from 'fp-ts/lib/IO';
import { handleFeedbackErrorIo } from '@utils/errorHandlers/feedbackErrorHandler'; // IO-based Feedback-specific error handler
import { DefaultFeedbackClient } from '@services/ouroboros/DefaultFeedbackClient';
import { StubFeedbackClient } from '@services/stubs/StubFeedbackClient';
import { SubmitFeedbackRequest } from '@model/clients/FeedbackApi';
import { enabledFeatures, FeaturesEnum } from '@src/utils/enabledFeatures';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppStackParamList, WorkspaceRoutesParamList } from '@navigation/NavigationRouteParameters';
import { AppRoutes, WorkspaceRoutes } from '@navigation/RouteEnums';
import {Ok} from "@model/Ok";
import {Effect} from "@utils/Effect"; // Feature flag utility

type FeedbackSubmissionProps = {
    navigation: StackNavigationProp<WorkspaceRoutesParamList, typeof WorkspaceRoutes.FEEDBACK_SUBMISSION>;
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.WORKSPACE>;
};

const FeedbackSubmissionPage: React.FC<FeedbackSubmissionProps> = ({ navigation}) => {
    // Select the appropriate client based on the feature flag
    const feedbackClient = useMemo(() => {
        if (enabledFeatures[FeaturesEnum.StubClients]) {
            return new StubFeedbackClient(); // Use stub client when feature flag is enabled
        } else {
            return new DefaultFeedbackClient(); // Use the default implementation otherwise
        }
    }, []);

    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useIoBasedLoadingSetters(); // Use IO-based loading context setters

    // Task to handle feedback submission
    const handleFeedbackSubmit = (feedback: SubmitFeedbackRequest): T.Task<Ok> => {
        return pipe(
            T.fromIO(triggerLoadingState), // Set loading state
            T.flatMap(() =>
                pipe(
                    feedbackClient.submitFeedback(feedback), // Submit feedback via the selected client
                    fold(
                        (error) => T.fromIO(handleFeedbackErrorIo(error, triggerErrorState)), // Handle error case
                        () => T.fromIO(triggerSuccessState) // Handle success case (trigger success state directly)
                    )
                )
            )
        );
    };

    // IO-based navigation to document selection page
    const navigateToDocumentSelection: IO.IO<Ok> = Effect.asOk(() =>
        navigation.navigate(WorkspaceRoutes.DOCUMENT_SELECTION)
    )

    return (
        <FeedbackSubmissionComponent
            submitFeedbackTask={handleFeedbackSubmit}
            navigateToDocumentSelection={navigateToDocumentSelection}
        />
    );
};

export default FeedbackSubmissionPage;
