// @components/workspace/FeedbackSubmission.tsx

import React, { useState } from "react";
import {
  View,
  Text,
  TextInput,
  Modal,
  TouchableOpacity,
} from "react-native";
import { FeedbackStyles as styles } from "@styles/Feedback.style"; // Import the relevant styles
import { SubmitFeedbackRequest } from "@model/clients/FeedbackApi";
import * as T from "fp-ts/lib/Task";
import * as IO from "fp-ts/lib/IO";
import { pipe } from "fp-ts/lib/function";
import { useIoState } from "@utils/useIoState";
import { Picker } from '@react-native-picker/picker';
import {Effect} from "@utils/Effect";
import {Ok} from "@model/Ok";

export type FeedbackSubmissionComponentProps = {
  submitFeedbackTask: (feedback: SubmitFeedbackRequest) => T.Task<Ok>; // Task-based submission handler
  navigateToDocumentSelection: IO.IO<Ok>;
};

const FeedbackSubmissionComponent: React.FC<
  FeedbackSubmissionComponentProps
> = ({ submitFeedbackTask, navigateToDocumentSelection }) => {
  const [feedbackContents, setFeedbackContents] = useIoState<string>("");
  const [category, setCategory] = useState<
    "Bug" | "FeatureRequest" | "General"
  >("General"); // default category
  const [showSuccess, setShowSuccess] = useIoState<boolean>(false); // Track success state

  const handleOnSubmit = Effect.asOk(() =>
      pipe({
      feedback: feedbackContents ,
      type: category,
    } as SubmitFeedbackRequest,

    // Submit feedback and clear fields on success
      feedbackRequest => submitFeedbackTask(feedbackRequest),
      T.flatMap(() => T.fromIO(setFeedbackContents(""))),
      T.flatMap(() => T.fromIO(setShowSuccess(true))) // Show success message
    ));

  const isFormDisabled = feedbackContents.length < 5

  const closeSuccessModalAndGoBackToHome = pipe(
    setShowSuccess(false),
    IO.flatMap(() => navigateToDocumentSelection)
  );

  const closeSuccessModal = pipe(
    setShowSuccess(false)
  );


  return (
    <View style={styles.container}>
      <Text style={styles.header}>Submit Your Feedback</Text>
      <Text style={styles.headertext}>Category:</Text>
      <View style={styles.categoryPicker}>
      <Picker
        selectedValue={category}
        style={styles.dropdownText}
        onValueChange={(itemValue) =>
          setCategory(itemValue)
        }>
        <Picker.Item label="General" value="General" />
        <Picker.Item label="Feature Request" value="FeatureRequest" />
        <Picker.Item label="Bug" value="Bug" />
      </Picker>
      </View>

      <TextInput
        style={{ marginBottom: 5, height: 100, ...styles.input }}
        value={feedbackContents}
        onChangeText={(text) => setFeedbackContents(text)()}
        placeholder="Type your feedback here..."
        placeholderTextColor={'gray'}
        multiline={true}
      />

      <TouchableOpacity disabled={isFormDisabled} style={[isFormDisabled ? styles.disabledButton : styles.button, {paddingBottom: 8}]} onPress={handleOnSubmit}>
        <Text style={styles.buttonText}>Submit Feedback</Text>
      </TouchableOpacity>

      {/* Success message modal */}
      <Modal
        visible={showSuccess}
        transparent={true}
        animationType="slide"
        onRequestClose={closeSuccessModalAndGoBackToHome}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.successMessage}>
              Thank you for your feedback!
            </Text>
            <View style={styles.gridContainer}>
              <View style={styles.gridRow}>

                <TouchableOpacity
                  style={styles.button}
                  onPress={closeSuccessModalAndGoBackToHome}
                >
                  <Text style={styles.buttonText}>Back to Home</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.button}
                  onPress={closeSuccessModal}
                >
                  <Text style={styles.buttonText}>Close</Text>
                </TouchableOpacity>

              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default FeedbackSubmissionComponent;
