// @components/workspace/creation-flow/DocumentOutline.tsx
import React from "react";
import { View, Text, TextInput } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { DocumentCreationStyles as styles } from "@styles/DocumentCreation.style";
import * as IO from "fp-ts/lib/IO";
import * as T from "fp-ts/lib/Task";
import { Ok } from "@model/Ok";
import * as O from "fp-ts/lib/Option";

export type NextAction = {
    action: T.Task<Ok>;
    label: string;
};

type DocumentOutlineProps = {
    outline: O.Option<string>;
    onOutlineChange: (text: string) => IO.IO<Ok>;
    nextAction: NextAction;
    onPrevious: IO.IO<Ok>;
};

const DocumentOutlineView: React.FC<DocumentOutlineProps> = ({
                                                                 outline,
                                                                 onOutlineChange,
                                                                 nextAction,
                                                                 onPrevious,
                                                             }) => (
    <View style={styles.containerDoc}>
        <Text style={styles.heading}>Planning Outline</Text>
        <TextInput
            style={styles.multiline}
            placeholder="Please write your outline with one section title per line. You need at least one section. Avoid using bullets or numbering."
            value={O.getOrElse(() => "")(outline)}
            placeholderTextColor={"gray"}
            onChangeText={(newText) => onOutlineChange(newText)()}
            multiline={true}
        />
        <View style={styles.viewStyle}>
            <TouchableOpacity style={styles.button2} onPress={onPrevious}>
                <Text style={styles.buttonText}>Back</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button2} onPress={() => nextAction.action()}>
                <Text style={styles.buttonText}>{nextAction.label}</Text>
            </TouchableOpacity>
        </View>
    </View>
);

export default DocumentOutlineView;
