// @pages/document/DocumentDetailsPrompt.page.tsx

import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { useRoute } from '@react-navigation/native';
import { DocumentRoutes } from '@navigation/RouteEnums';
import { DocumentRoutesParamList } from '@navigation/NavigationRouteParameters';
import DocumentDetailsPromptComponent from '@src/components/document/DocumentDetailsPrompt';
import { Markdown } from '@model/markdown';
import { useContentClient } from '@contexts/ContentClient.context';
import { useDocumentID } from '@contexts/Document.context';
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';
import * as IO from 'fp-ts/lib/IO'; // Import IO utilities
import { useIoBasedLoadingSetters } from '@contexts/Loading.context'; // Switch to IO-based setters
import { handleContentErrorIo } from '@utils/errorHandlers/contentErrorHandler'; // Switch to IO-based error handler
import { ContentClient } from '@services/clients/ContentClient';
import {Ok} from "@model/Ok";
import {Effect} from "@utils/Effect";

type DocumentDetailsPromptProps = {
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.DOCUMENT_LEVEL_PROMPT>;
};

// IO-based navigation to the review page
const navigateToReviewIo = (
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.DOCUMENT_LEVEL_PROMPT>
): IO.IO<Ok> => Effect.asOk(() =>
    navigation.replace(DocumentRoutes.REVIEW)
);

// IO-based success handler to trigger success and navigate
const handleSuccessIo = (
    triggerSuccessState: IO.IO<Ok>,
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.DOCUMENT_LEVEL_PROMPT>
): IO.IO<Ok> =>
    pipe(
        triggerSuccessState,
        IO.chain(() => navigateToReviewIo(navigation))
    );

// IO-based submission handling for prompt success/error
const submitPromptIo = (
    userInput: string,
    documentID: string,
    contentClient: ContentClient,
    triggerLoadingState: IO.IO<Ok>,
    triggerSuccessState: IO.IO<Ok>,
    triggerErrorState: (message: string) => IO.IO<Ok>,
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.DOCUMENT_LEVEL_PROMPT>
): T.Task<Ok> =>
    pipe(
        T.fromIO(triggerLoadingState), // Start loading state
        T.chain(() =>
            pipe(
                contentClient.answerDocumentQuestions(documentID, { answers: userInput }), // Submit answers
                fold(
                    (error) => T.fromIO(handleContentErrorIo(error, triggerErrorState)), // Handle error
                    () => T.fromIO(handleSuccessIo(triggerSuccessState, navigation)) // Handle success and navigate
                )
            )
        )
    );

const DocumentDetailsPromptPage: React.FC<DocumentDetailsPromptProps> = ({ navigation }) => {
    const { contentClient } = useContentClient();
    const route = useRoute();
    const { probingQuestion } = route.params as { probingQuestion: Markdown };
    const documentID = useDocumentID();
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useIoBasedLoadingSetters(); // Use IO-based setters

    const handlePromptSubmit = (userInput: string): T.Task<Ok> =>
        submitPromptIo(
            userInput,
            documentID,
            contentClient,
            triggerLoadingState,
            triggerSuccessState,
            triggerErrorState,
            navigation
        );

    return <DocumentDetailsPromptComponent onSubmitTask={handlePromptSubmit} documentQuestion={probingQuestion} />;
};

export default DocumentDetailsPromptPage;
