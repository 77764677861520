import { ContentClient } from '@services/clients/ContentClient';
import { DocumentID } from '@model/documents/core';
import {
    ReplaceSectionRequest,
    ReplaceSectionResponse,
    AnswerSectionQuestionsRequest,
    AnswerSectionQuestionsResponse,
    GenerateSectionQuestionsRequest,
    GenerateSectionQuestionsResponse,
    RefineRequest,
    RefineResponse,
    AnswerDocumentQuestionsRequest,
    GenerateDocumentQuestionsResponse,
    ContentError,
    AppendSectionRequest,
    AppendSectionResponse,
    UploadSupplementalDocumentRequest,
} from '@model/clients/ContentApi';
import API_URL from '@utils/config';
import * as t from 'io-ts';
import { v7 as uuidv7 } from 'uuid';
import { TaskEither, tryCatch, left, chain, map } from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';
import { Ok } from "@model/Ok";

export class DefaultContentClient implements ContentClient {
    constructor() {
        // No dependencies
    }

    private generateTraceId(): string {
        return uuidv7();
    }

    private fetchWithTrace(url: string, options: RequestInit): TaskEither<ContentError, Response> {
        const traceId = this.generateTraceId();
        options.headers = {
            ...options.headers,
            'X-Trace-ID': traceId,
        };
        options.credentials = 'include';

        return tryCatch(
            async () => await fetch(url, options),
            (_error) =>
                ({
                    type: 'NetworkError',
                    message: 'Failed to connect to the server. Please check your internet connection.',
                } as ContentError)
        );
    }

    private validateResponse<T>(
        response: Response,
        validator: t.Type<T>
    ): TaskEither<ContentError, T> {
        return tryCatch(
            async () => {
                const data = await response.json();
                const decoded = validator.decode(data);
                if (decoded._tag === 'Right') {
                    return decoded.right;
                } else {
                    throw new Error('Validation failed');
                }
            },
            () =>
                ({
                    type: 'ValidationError',
                    message: 'Response validation failed',
                } as ContentError)
        );
    }

    // New helper that wraps the common error handling
    private handleResponse<T>(response: Response, validator: t.Type<T>): TaskEither<ContentError, T> {
        return response.ok
            ? this.validateResponse<T>(response, validator)
            : left({
                type: 'ServerError',
                message: `Server responded with an error: ${response.status}`,
                statusCode: response.status,
            } as ContentError);
    }

    refineSection(
        documentId: DocumentID,
        request: RefineRequest
    ): TaskEither<ContentError, RefineResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document/refine`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
                body: JSON.stringify(request),
            }),
            chain((response) => this.handleResponse(response, RefineResponse))
        );
    }

    replaceSection(
        documentId: DocumentID,
        request: ReplaceSectionRequest
    ): TaskEither<ContentError, ReplaceSectionResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document/replace-section`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
                body: JSON.stringify(request),
            }),
            chain((response) => this.handleResponse(response, ReplaceSectionResponse))
        );
    }

    answerSectionQuestions(
        documentId: DocumentID,
        request: AnswerSectionQuestionsRequest
    ): TaskEither<ContentError, AnswerSectionQuestionsResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document/answer-section-questions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
                body: JSON.stringify(request),
            }),
            chain((response) => this.handleResponse(response, AnswerSectionQuestionsResponse))
        );
    }

    generateSectionQuestions(
        documentId: DocumentID,
        request: GenerateSectionQuestionsRequest
    ): TaskEither<ContentError, GenerateSectionQuestionsResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document/generate-section-questions`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
                body: JSON.stringify(request),
            }),
            chain((response) => this.handleResponse(response, GenerateSectionQuestionsResponse))
        );
    }

    answerDocumentQuestions(
        documentId: DocumentID,
        request: AnswerDocumentQuestionsRequest
    ): TaskEither<ContentError, Ok> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document/answer-document-questions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
                body: JSON.stringify(request),
            }),
            map(() => Ok)
        );
    }

    generateDocumentQuestions(
        documentId: DocumentID
    ): TaskEither<ContentError, GenerateDocumentQuestionsResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document/generate-document-questions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
            }),
            chain((response) => this.handleResponse(response, GenerateDocumentQuestionsResponse))
        );
    }

    appendSection(
        documentId: DocumentID,
        request: AppendSectionRequest
    ): TaskEither<ContentError, AppendSectionResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/apps/writer/document/append-section`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId,
                },
                body: JSON.stringify(request),
            }),
            chain((response) => this.handleResponse(response, AppendSectionResponse))
        );
    }

    uploadSupplementalDocument(
        documentId: DocumentID,
        request: UploadSupplementalDocumentRequest
    ): TaskEither<ContentError, Ok> {
        return pipe(
            tryCatch(
                async () => {
                    const formData = new FormData();
                    formData.append('file', request.file as Blob, request.fileName);
                    formData.append('relation', request.relation); // Attach the relation type
                    formData.append('document-id', documentId); // Attach the relation type

                    return await fetch(`${API_URL}/v1/apps/writer/document/upload-supplemental`, {
                        method: 'POST',
                        body: formData,
                        credentials: 'include',
                    });
                },
                (e) => {
                    if (e instanceof TypeError) {
                        return {
                            type: 'NetworkError',
                            message: 'Failed to connect to the server. Please check your internet connection.',
                        } as ContentError;
                    }
                    return e as ContentError;
                }
            ),
            map(() => Ok)
        );
    }
}
