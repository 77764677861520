// @pages/document/Refine.page.tsx

import React, { useEffect } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { DocumentRoutes } from '@navigation/RouteEnums';
import { DocumentRoutesParamList } from '@navigation/NavigationRouteParameters';
import RefineComponent from '@src/components/document/Refine';
import { useContentClient } from '@contexts/ContentClient.context';
import { useNonNullDocumentContext } from '@contexts/Document.context';
import { Section } from '@src/model/documents/core';
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';
import * as IO from 'fp-ts/lib/IO';
import { useIoBasedLoadingSetters } from '@contexts/Loading.context'; // Update to IO-based setters
import { handleContentErrorIo } from '@utils/errorHandlers/contentErrorHandler'; // Update to IO-based error handling
import { useIoState } from '@utils/useIoState';
import {Ok} from "@model/Ok";
import {Effect} from "@utils/Effect"; // Update to IO state

type RefineProps = {
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.REFINE>;
};

const RefinePage: React.FC<RefineProps> = ({ navigation }) => {
    const { contentClient } = useContentClient();
    const { activeSectionID, updateSectionIo, document: userDocument, } = useNonNullDocumentContext();
    const [mostRecentSection, setMostRecentSection] = useIoState<Section | null>(null); // Use IO state

    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useIoBasedLoadingSetters();

    // Set the most recent section based on the active section
    useEffect(() => {
        if (activeSectionID && userDocument) {
            const section = userDocument.document_contents.find(
                (sec) => sec.metadata.id === activeSectionID
            ) || null;
            setMostRecentSection(section)(); // Trigger IO setter
        }
    }, [activeSectionID, userDocument, setMostRecentSection]);

    // IO for navigating to the review page
    const navigateToReviewIo: IO.IO<Ok> = Effect.asOk(() => navigation.replace(DocumentRoutes.REVIEW));

    // IO for handling success case (updating section and navigating)
    const handleSuccessIo = (updatedSection: Section): IO.IO<Ok> =>
        pipe(
            updateSectionIo(updatedSection), // Update section
            IO.flatMap(() => navigateToReviewIo), // Navigate to the review page
            IO.flatMap(() => triggerSuccessState) // Set success state
        );

    // Handle refinement submission
    const handleRefinement: (feedback: string) => T.Task<Ok> = (feedback) =>
        mostRecentSection
            ? pipe(
                T.fromIO(triggerLoadingState),
                T.flatMap(() =>
                    pipe(
                        contentClient.refineSection(userDocument.id, {
                            feedback,
                            section_id: mostRecentSection.metadata.id,
                        }),
                        fold(
                            (error) => T.fromIO(handleContentErrorIo(error, triggerErrorState)), // Handle errors with IO
                            (response) => T.fromIO(handleSuccessIo(response.updated_section)) // Handle success with IO
                        )
                    )
                )
            )
            : T.of(Ok); // Return no-op task if no section is available

    return (
        <RefineComponent
            onSubmitTask={handleRefinement}
            mostRecentSection={mostRecentSection}
        />
    );
};

export default RefinePage;
