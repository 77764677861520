// @utils/useIoOptionState.ts
import { useState, useCallback } from 'react';
import * as IO from 'fp-ts/lib/IO';
import { Option, some } from 'fp-ts/lib/Option';
import { Ok } from '@model/Ok';

/**
 * A custom hook for managing state as an Option.
 *
 * @param initialState - The initial state wrapped as an Option.
 * @returns An object containing:
 *   - value: the current Option state,
 *   - setSome: a function that accepts a plain value of type A and sets the state to Some(value), returning IO<Ok>,
 *   - setMaybe: a function that accepts an Option<A> and sets the state accordingly, returning IO<Ok>.
 */
export function useIoOptionState<A>(initialState: Option<A>) {
  const [value, setValue] = useState<Option<A>>(initialState);

  // setSome: updates the state to Some(value) for a given raw value.
  const setSome = useCallback((a: A): IO.IO<Ok> => {
    return IO.as(Ok)(() => setValue(some(a)));
  }, []);

  // setMaybe: updates the state with the given Option<A>.
  const setMaybe = useCallback((option: Option<A>): IO.IO<Ok> => {
    return IO.as(Ok)(() => setValue(option));
  }, []);

  return { value, setSome, setMaybe };
}
