import React, { useState } from "react";
import { View, StyleSheet, Platform, Text } from "react-native";
import { Button, Card, Snackbar } from "react-native-paper"; // Using Snackbar for message feedback
import Clipboard from "@react-native-clipboard/clipboard"; // Import the Clipboard API
import * as IO from "fp-ts/IO"; // Import Task utilities
import * as T from "fp-ts/lib/Task";
import { useIoBasedLoadingSetters } from "@src/contexts/Loading.context";
import {Ok} from "@model/Ok";

type SectionActionsProps = {
  sectionContents: string | null;
  handleDraft: T.Task<Ok>;
  handleRefine: IO.IO<Ok>; // Change to a function type for direct invocation
  handleFinalEdits: IO.IO<Ok>;
  handleUpload: T.Task<Ok>;
};

const SectionActions: React.FC<SectionActionsProps> = ({
  sectionContents,
  handleDraft,
  handleRefine,
  handleFinalEdits,
  handleUpload,
}) => {
  const [visible, setVisible] = useState(false);
  const { triggerSuccessState } = useIoBasedLoadingSetters();

  // Handle copy to clipboard for markdown or HTML
  const handleCopy = async (content: string) => {
    try {
      // Copy to clipboard
      if (Platform.OS === "web") {
        // Use the browser's clipboard API
        await navigator.clipboard.writeText(content);
        console.log("Copied to clipboard");
      } else {
        // Use the Clipboard API for mobile platforms
        Clipboard.setString(content);
        console.log("Copied to clipboard (mobile)");
      }

      triggerSuccessState();

      setVisible(true); // Show the Snackbar to notify success
    } catch (err) {
      console.error("Failed to copy text: ", err);
      // Optionally, display a user-friendly error message here, e.g. with a toast notification
    }
  };

  return (
    <Card style={styles.actionCard}>
      <View style={styles.buttonContainer}>
        {!sectionContents && (
          <Button style={{ borderRadius: 10 }} onPress={() => handleDraft()}>
            <Text style={{ color: "#000" }}>Draft</Text>
          </Button>
        )}
        {sectionContents && (
          <>
            <Button style={{ borderRadius: 10 }} onPress={handleRefine}>
              <Text style={{ color: "#000" }}>Refine</Text>
            </Button>
            <Button style={{ borderRadius: 10 }} onPress={handleFinalEdits}>
              <Text style={{ color: "#000" }}>Edit</Text>
            </Button>
            <Button style={{ borderRadius: 10 }} onPress={handleUpload}>
              <Text style={{ color: "#000" }}>Upload</Text>
            </Button>
            {/* Add the Copy buttons */}
            <Button
              style={{ borderRadius: 10 }}
              onPress={() => handleCopy(sectionContents)}
            >
              <Text style={{ color: "#000" }}>Copy Markdown</Text>
            </Button>
          </>
        )}
      </View>
      <Snackbar
        visible={visible}
        onDismiss={() => setVisible(false)}
        duration={1500}
      >
        Content copied to clipboard!
      </Snackbar>
    </Card>
  );
};

const styles = StyleSheet.create({
  actionCard: {
    paddingVertical: 5,
    marginTop: -8, // Move the card closer to the section above it
    marginBottom: 8, // Ensure there's still space below
    backgroundColor: "#f2f2f2", // Lighter background color for better contrast
  },
  buttonContainer: {
    flexDirection: "row",
    paddingHorizontal: 20,
    justifyContent: "space-between",
  },
});

export default SectionActions;
