import { useState } from "react";
import * as TO from "fp-ts/lib/TaskOption";
import * as O from "fp-ts/lib/Option";

// Custom hook for selecting a file
export const useFilePicker = () => {
  const [_file, setFile] = useState<O.Option<File>>(O.none);

  const selectFile = (): TO.TaskOption<File> => {
    return () =>
        new Promise<O.Option<File>>((resolve) => {
          const input = document.createElement("input");
          input.type = "file";
          input.accept = ".txt";

          input.onchange = () => {
            const selectedFile = input.files?.[0] || null;
            const optionFile = O.fromNullable(selectedFile);
            setFile(optionFile);
            resolve(optionFile);
          };

          input.click();
        });
  };

  return {
    selectFile,
  };
};
