// @pages/workspace/DocumentCreation.tsx
import React from "react";
import { View } from "react-native";
import * as T from "fp-ts/lib/Task";
import * as IO from "fp-ts/lib/IO";
import { useIoOptionState } from "@utils/useIoOptionState";
import { useFilePicker } from "@src/hooks/useFilePicker";
import { usePhaseFlow } from "@src/hooks/usePhaseFlow";
import DocumentSelectionView from "@components/workspace/creation-flow/DocumentSelection";
import DocumentUploadView from "@components/workspace/creation-flow/DocumentUpload";
import DocumentNameView from "@components/workspace/creation-flow/DocumentName";
import DocumentIntentionsView from "@components/workspace/creation-flow/DocumentIntentions";
import DocumentOutlineView from "@components/workspace/creation-flow/DocumentOutline";
import { DocumentCreationStyles as styles } from "@styles/DocumentCreation.style";
import { Ok } from "@model/Ok";
import * as TO from "fp-ts/lib/TaskOption";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as E from "fp-ts/lib/Either";
import { buildCreateDocumentRequest } from "@model/documents/CreateDocumentRequestBuilder";
import { CreateDocumentRequest, DocumentWizardType } from "@model/clients/LifecycleApi";
import { Phase } from "@model/documents/phaseFlow";

type NextAction = {
  action: T.Task<Ok>;
  label: string;
};

type DocumentCreationProps = {
  // onCreateDocumentTask receives an Either (error or valid request)
  // and returns a Task that produces an Ok.
  onCreateDocumentTask: (req: E.Either<string, CreateDocumentRequest>) => T.Task<Ok>;
};

const DocumentCreation: React.FC<DocumentCreationProps> = ({ onCreateDocumentTask }) => {
  // Manage text fields as Option<string> using useIoOptionState.
  // These start as none so that children know no text has been entered.
  const { value: title, setSome: setTitle } = useIoOptionState<string>(O.none);
  const { value: styleText, setSome: setStyleText } = useIoOptionState<string>(O.none);
  const { value: outline, setSome: setOutline } = useIoOptionState<string>(O.none);

  // Manage documentType and uploadedDocument as Option values.
  const { value: documentType, setSome: setDocumentType } = useIoOptionState<DocumentWizardType>(O.none);
  const { value: uploadedDocument, setSome: setUploadedDocument } = useIoOptionState<File>(O.none);

  const { selectFile } = useFilePicker();

  // usePhaseFlow now accepts an Option for documentType and always returns a valid phase.
  // We use O.getOrElse to provide a default ("scratch") in case documentType is none.
  const { currentPhase, nextPhase, previousPhase, hasNext } = usePhaseFlow(
      O.getOrElse<DocumentWizardType | 'undetermined'>(() => "undetermined")(documentType)
  );
  const phase: Phase = O.getOrElse<Phase>(() => 'documentWizardTypeSelection')(currentPhase);

  // Compute a unified NextAction.
  const nextAction: IO.IO<NextAction> = () => hasNext  // Don't try to build requests until you know the wizard type
      ? {
        action: T.fromIO(nextPhase), // Lift the next-phase IO to a Task.
        label: "Next",
      }
      : {
        action: onCreateDocumentTask(
            buildCreateDocumentRequest(title, styleText, outline, documentType, uploadedDocument)
        ),
        label: "Create Document",
      };

  // Use TaskOption to chain file selection with updating the uploadedDocument state.
  const taskHandleFileSelection: TO.TaskOption<Ok> = pipe(
      selectFile(),
      TO.flatMap(file => TO.fromIO(setUploadedDocument(file)))
  );

  // Build the CreateDocumentRequest using our helper.
  // (In our NextAction above we call buildCreateDocumentRequest directly.)
  // The parent's onCreateDocumentTask now receives an Either so errors are handled upstream.
  // Note: We no longer unsafely throw an exception here.

  // Render the appropriate subcomponent based on the current phase.
  const renderPhaseView = () => {
    switch (phase) {
      case "documentWizardTypeSelection":
        return <DocumentSelectionView onSelect={setDocumentType} />;
      case "upload":
        return (
            <DocumentUploadView
                uploadedDocument={uploadedDocument}
                onFileSelect={taskHandleFileSelection}
                nextAction={nextAction()}
                onPrevious={previousPhase}
            />
        );
      case "documentName":
        return (
            <DocumentNameView
                title={title}
                onTitleChange={setTitle}
                nextAction={nextAction()}
                onPrevious={previousPhase}
            />
        );
      case "intentions":
        return (
            <DocumentIntentionsView
                styleText={styleText}
                onStyleChange={setStyleText}
                nextAction={nextAction()}
                onPrevious={previousPhase}
            />
        );
      case "outline":
        return (
            <DocumentOutlineView
                outline={outline}
                onOutlineChange={setOutline}
                nextAction={nextAction()}
                onPrevious={previousPhase}
            />
        );
      default:
        return null;
    }
  };

  return <View style={styles.containerDoc}>{renderPhaseView()}</View>;
};

export default DocumentCreation;
