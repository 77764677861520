// @pages/document/FinalEdits.page.tsx

import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { useFocusEffect } from '@react-navigation/native';
import { DocumentRoutes } from '@navigation/RouteEnums';
import { DocumentRoutesParamList } from '@navigation/NavigationRouteParameters';
import FinalEditsComponent from '@src/components/document/FinalEdits';
import { useContentClient } from '@contexts/ContentClient.context';
import { useNonNullDocumentContext } from '@contexts/Document.context';
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';
import * as IO from 'fp-ts/lib/IO';
import { useIoBasedLoadingSetters } from '@contexts/Loading.context'; // Using io based setters
import { handleContentErrorIo } from '@utils/errorHandlers/contentErrorHandler'; // Using io based error handling
import { Section } from '@model/documents/core';
import { UserSectionReplacement } from '@model/clients/ContentApi';
import { useIoState } from '@utils/useIoState';
import {Ok} from "@model/Ok";
import {Effect} from "@utils/Effect"; // Use io state instead of useState

type FinalEditsProps = {
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.FINAL_EDITS>;
};

const FinalEditsPage: React.FC<FinalEditsProps> = ({ navigation }) => {
    const { contentClient } = useContentClient();
    const { document, activeSectionID, updateSectionIo } = useNonNullDocumentContext();
    const [finalEdits, setFinalEdits] = useIoState(''); // Using io state

    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useIoBasedLoadingSetters();

    // Set final edits based on active section
    useFocusEffect(
        React.useCallback(() => {
            if (activeSectionID) {
                const section = document.document_contents.find(
                    (sec) => sec.metadata.id === activeSectionID
                );
                if (section) {
                    setFinalEdits(section.section_contents || '')(); // Trigger the io setter
                }
            }
        }, [document, activeSectionID, setFinalEdits])
    );

    // Navigation IO to move to review page
    const navigateToReviewIo: IO.IO<Ok> = Effect.asOk(() => navigation.replace(DocumentRoutes.REVIEW));

    // IO for handling section update success
    const handleUpdateSectionSuccessIo = (updatedSection: Section): IO.IO<Ok> =>
        pipe(
            updateSectionIo(updatedSection),
            IO.flatMap(() => navigateToReviewIo), // Use IO for navigation
            IO.flatMap(() => triggerSuccessState)
        );

    // Handle final edits submission
    const handleFinalEdits: T.Task<Ok> = pipe(
        T.fromIO(triggerLoadingState),
        T.flatMap(() =>
            pipe(
                contentClient.replaceSection(document.id, {
                    edits: finalEdits as UserSectionReplacement,
                    section_id: activeSectionID!,
                }),
                fold(
                    (error) => T.fromIO(handleContentErrorIo(error, triggerErrorState)), // Handle errors with IO
                    (response) => T.fromIO(handleUpdateSectionSuccessIo(response.updated_section)) // Handle success
                )
            )
        )
    );

    return (
        <FinalEditsComponent
            finalEdits={finalEdits}
            setFinalEdits={setFinalEdits} // Trigger IO on change
            onSubmitTask={handleFinalEdits}
        />
    );
};

export default FinalEditsPage;
