// @utils/paymentErrorHandler.ts

import { PaymentError } from '@model/clients/PaymentApi';
import * as IO from 'fp-ts/lib/IO';
import {Ok} from "@model/Ok"; // Import IO utilities

export const handlePaymentErrorIo = (
    error: PaymentError,
    setError: (message: string) => IO.IO<Ok>
): IO.IO<Ok> => {
    switch (error.type) {
        case 'NetworkError':
            return setError('Network error. Please check your internet connection.');
        case 'ValidationError':
            return setError(error.message || 'Validation error occurred.');
        case 'ServerError':
            return setError(`Server error: ${error.message} (Status code: ${error.statusCode})`);
        case 'UnknownError':
        default:
            console.error(error);
            return setError(error.message || 'An unknown error occurred. Please try again.');
    }
};
