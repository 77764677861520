// @services/ouroboros/DefaultAccountClient.ts

import { AccountClient } from '@services/clients/AccountClient';
import { AccountResponse, AccountError } from '@model/clients/AccountApi';
import API_URL from '@utils/config';
import { v7 as uuidv7 } from 'uuid';
import { TaskEither, tryCatch, left, chain} from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';

export class DefaultAccountClient implements AccountClient {
    constructor() {
        // No dependencies needed
    }

    // Generate a new trace ID for tracking the request
    private generateTraceId(): string {
        return uuidv7();
    }

    // Helper method to fetch with trace ID
    private fetchWithTrace(
        url: string,
        options: RequestInit
    ): TaskEither<AccountError, Response> {
        const traceId = this.generateTraceId();
        options.headers = {
            ...options.headers,
            'X-Trace-ID': traceId,
        };

        options.credentials = 'include'; // Ensures credentials are included

        return tryCatch(
            async () => {
                const response = await fetch(url, options);

                if (!response.ok) {
                    throw {
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as AccountError;
                }

                return response;
            },
            (e) => {
                if (e instanceof TypeError) {
                    return {
                        type: 'NetworkError',
                        message: 'Failed to connect to the server. Please check your internet connection.',
                    } as AccountError;
                }
                return e as AccountError;
            }
        );
    }

    // Helper method to validate and transform the response
    private validateAndTransformResponse(response: Response): TaskEither<AccountError, AccountResponse> {
        return tryCatch(
            async () => {
                const data = await response.json();
                const decoded = AccountResponse.decode(data);

                if (decoded._tag === 'Right') {
                    const transformed: AccountResponse = {
                        ...decoded.right,
                        expiration: new Date(decoded.right.expiration), // Transform the expiration from string to Date
                    };
                    return transformed;
                } else {
                    throw new Error('Validation failed');
                }
            },
            () =>
                ({
                    type: 'ValidationError',
                    message: 'Response validation or transformation failed',
                } as AccountError)
        );
    }

    // Implementation of the getAccountDetails method
    getAccountDetails(): TaskEither<AccountError, AccountResponse> {
        return pipe(
            this.fetchWithTrace(`${API_URL}/v1/account/details`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            chain((response) =>
                response.ok
                    ? this.validateAndTransformResponse(response)
                    : left({
                        type: 'ServerError',
                        message: `Server responded with an error: ${response.status}`,
                        statusCode: response.status,
                    } as AccountError)
            )
        );
    }
}
