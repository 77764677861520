import React from 'react';
import { DocumentProvider } from '@contexts/Document.context';
import { NavigationContainer } from '@react-navigation/native';
import AppNavigator from '@navigation/navigator/App.navigator';
import { Provider as PaperProvider } from 'react-native-paper';
import LoadingOverlay from '@components/LoadingOverlay';
import {LoadingProvider} from "@contexts/Loading.context";
import {AuthenticationClientProvider} from "@contexts/AuthenticationClient.context";
import {DocumentListProvider} from "@contexts/DocumentList.context";
import {LifecycleClientProvider} from "@contexts/LifecycleClient.context";
import {JwtProvider} from "@contexts/Jwt.context";
import Toast from 'react-native-toast-message';

const App: React.FC = () => {
    return (
        <PaperProvider>
            <LoadingProvider>
                    <AuthenticationClientProvider>
                        <JwtProvider>
                        <LifecycleClientProvider>
                            <DocumentProvider>
                                        <DocumentListProvider>
                                            <NavigationContainer>
                                                <AppNavigator />
                                                <LoadingOverlay />
                                            </NavigationContainer>
                                        </DocumentListProvider>
                            </DocumentProvider>
                        </LifecycleClientProvider>
                        </JwtProvider>
                    </AuthenticationClientProvider>
            </LoadingProvider>
            <Toast />
        </PaperProvider>
    );
};

export default App;
