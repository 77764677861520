// @types/client/AuthenticationApi.ts
import * as t from "io-ts";

export type Email = string & { readonly brand: unique symbol };
export type Password = string & { readonly brand: unique symbol };

export type SubscriptionStatus = 'Paid' | 'PaymentFailed' | 'FreeTrialMode' | 'PaymentNotYetConfigured' | 'Canceled'
export const SubscriptionStatusCodec = t.union([
    t.literal('Paid'),
    t.literal('PaymentFailed'),
    t.literal('FreeTrialMode'),
    t.literal('PaymentNotYetConfigured'),
    t.literal('Canceled'),
]);

export const SubscriptionStatusResponse = t.type({
    subscription_status: SubscriptionStatusCodec,
});
export type SubscriptionStatusResponse = t.TypeOf<typeof SubscriptionStatusResponse>;

export type JwtState = 'authorized' | 'unauthorized'
export type NotFound = 'not-found'
export interface JwtResponse {
    status: JwtState;
    subscription_status: SubscriptionStatus | NotFound;
}

// Utility function to enforce the brand when creating values
export const Email = (value: string): Email => value as Email;
export const Password = (value: string): Password => value as Password;

export interface LoginRequest {
    email: Email;
    password: Password;
}

export type CreateAccountRequest = {
    email: Email;
    password: Password;
};

export const CreateAccountResponse = t.type({
    payment_intent: t.string,
    stripe_user_key: t.string
});
export type CreateAccountResponse = t.TypeOf<typeof CreateAccountResponse>;

export type AuthenticationError =
    | { type: 'NetworkError'; message: string }
    | { type: 'ServerError'; message: string; statusCode: number }
    | { type: 'ValidationError'; message: string }
    | { type: 'ExistingAccountError'; message: string }
    | { type: 'Unauthorized'; message: string }
    | { type: 'UnknownError'; message: string };
