// @model/documents/phaseFlow.ts

import { Option, some, none, fold as foldOption } from "fp-ts/Option";
import { Either, right, left } from "fp-ts/Either";

export type Phase = "upload" | "documentName" | "intentions" | "outline" | "documentWizardTypeSelection";

interface PhaseNode {
    value: Phase;
    next: Option<PhaseNode>;
    prev: Option<PhaseNode>;
}

export class PhaseFlow {
    private head: PhaseNode;
    private tail: PhaseNode;
    private current: PhaseNode;

    private constructor(nodes: PhaseNode[]) {
        this.head = nodes[0];
        this.tail = nodes[nodes.length - 1];
        this.current = this.head;
    }

    // Factory method that ties the knot in one pass.
    public static create(phases: Phase[]): Either<string, PhaseFlow> {
        if (phases.length === 0) {
            return left("Phase list cannot be empty");
        }

        // Create nodes with placeholder pointers.
        const nodes: PhaseNode[] = phases.map((phase) => ({
            value: phase,
            next: none,
            prev: none,
        }));

        // Update each node's next and prev pointers in place.
        for (let i = 0; i < nodes.length; i++) {
            nodes[i].next = i < nodes.length - 1 ? some(nodes[i + 1]) : none;
            nodes[i].prev = i > 0 ? some(nodes[i - 1]) : none;
        }

        return right(new PhaseFlow(nodes));
    }

    public currentPhase(): Phase {
        return this.current.value;
    }

    public hasNext(): boolean {
        return foldOption<PhaseNode, boolean>(
            () => false,
            () => true
        )(this.current.next);
    }

    public hasPrevious(): boolean {
        return foldOption<PhaseNode, boolean>(
            () => false,
            () => true
        )(this.current.prev);
    }

    public next(): Option<Phase> {
        return foldOption<PhaseNode, Option<Phase>>(
            () => none,
            (node) => {
                this.current = node;
                return some(node.value);
            }
        )(this.current.next);
    }

    public previous(): Option<Phase> {
        return foldOption<PhaseNode, Option<Phase>>(
            () => none,
            (node) => {
                this.current = node;
                return some(node.value);
            }
        )(this.current.prev);
    }

    public reset(): void {
        this.current = this.head;
    }
}
