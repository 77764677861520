// @pages/workspace/DocumentCreation.page.tsx

import React from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppRoutes, DocumentRoutes, WorkspaceRoutes } from "@navigation/RouteEnums";
import { WorkspaceRoutesParamList, AppStackParamList } from "@navigation/NavigationRouteParameters";
import DocumentCreationComponent from "@src/components/workspace/DocumentCreation";
import {
  CreateDocumentRequest,
  CreateDocumentResponse,
  LifecycleError,
} from "@model/clients/LifecycleApi";
import { useLifecycleClient } from "@contexts/LifecycleClient.context";
import { useDocumentListContext } from "@contexts/DocumentList.context";
import { useDocumentContext } from "@contexts/Document.context";
import { pipe } from "fp-ts/lib/function";
import * as T from "fp-ts/lib/Task"; // Import Task utilities
import * as TE from "fp-ts/lib/TaskEither"; // Import TaskEither utilities
import * as IO from "fp-ts/lib/IO"; // Import TaskEither utilities
import * as E from "fp-ts/lib/Either"; // Import TaskEither utilities
import { useIoBasedLoadingSetters } from "@contexts/Loading.context"; // Use IO-based loading context setters
import { handleLifecycleErrorIo } from "@utils/errorHandlers/lifecycleErrorHandler";
import {Ok} from "@model/Ok";
import {Effect} from "@utils/Effect"; // IO-based error handler

type DocumentCreationProps = {
  navigation: StackNavigationProp<
    WorkspaceRoutesParamList,
    typeof WorkspaceRoutes.DOCUMENT_CREATION
  >;
  appNavigator: StackNavigationProp<
    AppStackParamList,
    typeof AppRoutes.WORKSPACE
  >;
};

const DocumentCreationPage: React.FC<DocumentCreationProps> = ({
  appNavigator,
}) => {
  const { lifecycleClient } = useLifecycleClient();
  const { refreshDocuments } = useDocumentListContext();
  const { setDocumentIo } = useDocumentContext();
  const { triggerLoadingState, triggerSuccessState, triggerErrorState } =
    useIoBasedLoadingSetters(); // Use IO-based loading context setters

  // Task to fetch document and refresh list
  const fetchDocumentAndRefreshTask = (
    documentID: string
  ): TE.TaskEither<LifecycleError, Ok> =>
    pipe(
      lifecycleClient.fetchDocument(documentID),
      TE.flatMap((document) => TE.rightTask(T.fromIO(setDocumentIo(document)))),
      TE.flatMap(() => TE.rightTask(refreshDocuments))
    );

  // IO to navigate to document level prompt page
  const navigateToDocSelect: IO.IO<Ok> = Effect.asOk( ()=>
    appNavigator.navigate(AppRoutes.WORKSPACE, {
      screen: WorkspaceRoutes.DOCUMENT_SELECTION,
    }));

  const navigateToDocumentPrompt =
    (probingQuestion: string): IO.IO<Ok> =>
    Effect.asOk(() =>
      appNavigator.navigate(AppRoutes.DOCUMENT, {
        screen: DocumentRoutes.DOCUMENT_LEVEL_PROMPT,
        params: { probingQuestion },
      }));

  const navigateToPromptThroughDocSelect = (
    probingQuestion: string
  ): IO.IO<Ok> =>
    pipe(
      navigateToDocSelect,
      IO.flatMap(() => navigateToDocumentPrompt(probingQuestion)),
      IO.as(Ok)
    );

  // Task to handle document creation
  const handleCreateDocument =(
      createDocumentRequest: E.Either<string,CreateDocumentRequest>
  ): T.Task<Ok> => {
    console.log("createDocumentRequest", createDocumentRequest);
    return pipe(
      T.fromIO(triggerLoadingState), // Start loading when document creation begins
      T.flatMap(() =>
        pipe(
          createDocumentRequest,
          TE.fromEither,
            TE.mapLeft(
                (errorString): LifecycleError => ({
                  type: "ValidationError",
                  message: errorString,
                })
            ),
          TE.flatMap(request => lifecycleClient.createDocument(request)),
          TE.flatMap((response: CreateDocumentResponse) =>
            pipe(
              fetchDocumentAndRefreshTask(response.document_id), // Fetch document and refresh list
              TE.flatMap(() =>
                TE.rightIO(
                  navigateToPromptThroughDocSelect(response.prompting_question)
                )
              ),
              TE.flatMap(() => TE.rightIO(triggerSuccessState)) // Trigger success state
            )
          ),
          TE.getOrElse((error: LifecycleError) =>
            T.fromIO(handleLifecycleErrorIo(error, triggerErrorState))
          ),
          T.as(Ok)
        )
      )
    );
  };

  return (
    <DocumentCreationComponent onCreateDocumentTask={handleCreateDocument} />
  );
};

export default DocumentCreationPage;
