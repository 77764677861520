// @contexts/DocumentList.context.tsx
import React, { createContext, useContext, ReactNode, useMemo } from 'react';
import { DocumentMetadata } from '@model/documents/core';
import { useIoBasedLoadingSetters } from '@contexts/Loading.context';
import { useLifecycleClient } from '@contexts/LifecycleClient.context';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';
import { useIoState } from '@utils/useIoState';
import {Ok} from "@model/Ok";

export interface DocumentListContextType {
    availableDocuments: DocumentMetadata[];
    fetchDocuments: T.Task<Ok>;
    refreshDocuments: T.Task<Ok>;
}

const DocumentListContext = createContext<DocumentListContextType | undefined>(undefined);

export const DocumentListProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [availableDocuments, setAvailableDocuments] = useIoState<DocumentMetadata[]>([]);

    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useIoBasedLoadingSetters();
    const { lifecycleClient } = useLifecycleClient();

    const fetchDocuments: T.Task<Ok> = useMemo(
        () =>
            pipe(
                T.fromIO(triggerLoadingState), // Trigger loading state using IO
                T.flatMap(() =>
                    pipe(
                        lifecycleClient.listAvailableDocuments(),
                        TE.fold(
                            (error) => T.fromIO(triggerErrorState('Failed to fetch documents', error.message)), // Handle error case with IO
                            (response) =>
                                pipe(
                                    T.fromIO(setAvailableDocuments(response.documents)), // Update state with IO
                                    T.flatMap(() => T.fromIO(triggerSuccessState)) // Trigger success state with IO
                                )
                        )
                    )
                )
            ),
        [
            triggerLoadingState,
            lifecycleClient,
            triggerErrorState,
            triggerSuccessState,
            setAvailableDocuments,
        ]
    );

    // Since `fetchDocuments` is already memoized, `refreshDocuments` can reference it directly
    const refreshDocuments: T.Task<Ok> = useMemo(() => fetchDocuments, [fetchDocuments]);

    const contextValue = useMemo(
        () => ({
            availableDocuments,
            fetchDocuments,
            refreshDocuments,
        }),
        [availableDocuments, fetchDocuments, refreshDocuments]
    );

    return <DocumentListContext.Provider value={contextValue}>{children}</DocumentListContext.Provider>;
};

export const useDocumentListContext = (): DocumentListContextType => {
    const context = useContext(DocumentListContext);
    if (!context) {
        throw new Error('useDocumentListContext must be used within a DocumentListProvider');
    }
    return context;
};
