// @model/documents/CreateDocumentRequestBuilder.ts
/**
 * This module exports a function to build a CreateDocumentRequest.
 * The function accepts all input fields as Option values and uses fp‑ts's sequenceT
 * to combine required fields for each document type. If any required field is missing,
 * an error message is returned as a Left; otherwise, a valid request is returned as Right.
 */

import { Either, left, fromOption } from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { sequenceT } from "fp-ts/lib/Apply";

// Import the request codecs and types.
import {
    CreateDocumentRequest,
    CreateDocumentScratchRequest,
    CreateDocumentExampleRequest,
    CreateDocumentEditRequest, DocumentWizardType,
} from "@model/clients/LifecycleApi";

/**
 * Builds a CreateDocumentRequest from optional inputs.
 *
 * For a "scratch" request, title, styleText, and outline are required.
 * For an "example" request, title, styleText, outline, and uploadedDocument are required.
 * For an "edit" request, only an uploadedDocument is required.
 *
 * @param title - Option containing the document name.
 * @param styleText - Option containing the style text.
 * @param outline - Option containing the outline.
 * @param documentType - Option containing the document type ("scratch" | "edit" | "example").
 * @param uploadedDocument - Option containing the uploaded document.
 * @returns Either an error message or a valid CreateDocumentRequest.
 */
export const buildCreateDocumentRequest = (
    title: O.Option<string>,
    styleText: O.Option<string>,
    outline: O.Option<string>,
    documentType: O.Option<"scratch" | "edit" | "example">,
    uploadedDocument: O.Option<File>
): Either<string, CreateDocumentRequest> =>
    pipe(
        documentType,
        O.fold(
            () => left("documentType missing"),
            (dt: DocumentWizardType) => {
                switch (dt) {
                    case "scratch":
                        return pipe(
                            sequenceT(O.Applicative)(title, styleText, outline),
                            O.map(([t, s, o]) => ({
                                type: "scratch",
                                name: t,
                                style: s,
                                outline: o,
                            } as CreateDocumentScratchRequest)),
                            fromOption(() => "Missing required fields for scratch request")
                        );
                    case "example":
                        return pipe(
                            sequenceT(O.Applicative)(title, styleText, outline, uploadedDocument),
                            O.map(([t, s, o, ud]) => ({
                                type: "example",
                                name: t,
                                style: s,
                                outline: o,
                                fileName: ud.name,
                                file: ud,
                            } as CreateDocumentExampleRequest)),
                            fromOption(() => "Missing required fields for example request")
                        );
                    case "edit":
                        return pipe(
                            uploadedDocument,
                            O.map(ud => ({
                                type: "edit",
                                fileName: ud.name,
                                file: ud,
                            } as CreateDocumentEditRequest)),
                            fromOption(() => "Missing uploaded document for edit request")
                        );
                    default:
                        return left("Invalid document type2");
                }
            }
        )
    );
