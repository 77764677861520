// @utils/errorHandlers.ts
import { AuthenticationError } from '@model/clients/AuthenticationApi';
import * as IO from 'fp-ts/lib/IO';
import {Ok} from "@model/Ok"; // Import IO utilities

export const handleAuthenticationErrorIo = (
    error: AuthenticationError,
    setError: (message: string) => IO.IO<Ok> // Now using IO instead of Task
): IO.IO<Ok> => {
    switch (error.type) {
        case 'Unauthorized':
            return setError('Unauthorized action. Please check your credentials.');
        case 'NetworkError':
            return setError('Network error. Please check your internet connection.');
        case 'ValidationError':
            return setError('The response from the server could not be read, please update your app or refresh your web browser. If the problem persists, contact support.');
        case 'ExistingAccountError':
            return setError('An account with that email already exists. Please try again with a different email or log in.');
        case 'ServerError':
            return setError(`Server error: ${error.message}`);
        default:
            console.error(error);
            return setError('An unknown error occurred. Please try again.');
    }
};
