// @components/workspace/creation-flow/DocumentSelection.tsx
import { View, Text } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { DocumentCreationStyles as styles } from "@styles/DocumentCreation.style";
import * as IO from "fp-ts/lib/IO";
import { Ok } from "@model/Ok";
import { enabledFeatures, FeaturesEnum } from "@utils/enabledFeatures";

type DocumentSelectionProps = {
    onSelect: (type: "scratch" | "edit" | "example") => IO.IO<Ok>;
};

const DocumentSelectionView: React.FC<DocumentSelectionProps> = ({ onSelect }) => {
    const advancedEnabled = enabledFeatures[FeaturesEnum.AdvancedDocumentCreation];

    return (
        <View style={styles.containerDoc}>
            <Text style={styles.heading}>Step 1: What do you want to do?</Text>
            <View style={styles.containerDocNew}>
                {advancedEnabled && (
                    <>
                        <TouchableOpacity
                            style={styles.button2}
                            onPress={() => onSelect("edit")()}
                        >
                            <Text style={styles.buttonText}>Edit Existing Document</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={styles.button2}
                            onPress={() => onSelect("example")()}
                        >
                            <Text style={styles.buttonText}>Write New Document (with Example)</Text>
                        </TouchableOpacity>
                    </>
                )}
                <TouchableOpacity
                    style={styles.button2}
                    onPress={() => onSelect("scratch")()}
                >
                    <Text style={styles.buttonText}>Create Document from Scratch</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default DocumentSelectionView;
