// @services/stubs/StubContentClient.ts

import { ContentClient } from '@services/clients/ContentClient';
import { DocumentID } from '@model/documents/core';
import {
    ReplaceSectionRequest,
    ReplaceSectionResponse,
    AnswerSectionQuestionsRequest,
    AnswerSectionQuestionsResponse,
    GenerateSectionQuestionsRequest,
    GenerateSectionQuestionsResponse,
    RefineRequest,
    RefineResponse,
    AnswerDocumentQuestionsRequest,
    GenerateDocumentQuestionsResponse,
    ContentError,
    AppendSectionRequest,
    AppendSectionResponse,
    UploadSupplementalDocumentRequest,
} from '@model/clients/ContentApi';
import { TaskEither, right } from 'fp-ts/lib/TaskEither';
import { delayTaskEither } from '@utils/delayTaskEither';
import { randomUUID } from "expo-crypto";
import { Ok } from '@model/Ok';

export class StubContentClient implements ContentClient {
    refineSection(
        documentId: DocumentID,
        request: RefineRequest
    ): TaskEither<ContentError, RefineResponse> {
        console.log('StubContentClient.refineSection called', documentId, request);
        return delayTaskEither(100, right({
            updated_section: {
                section_contents: request.feedback + '\n\nRefined content.',
                metadata: {
                    id: request.section_id,
                    name: 'Sample Section',
                    index: 1,
                    status: 'Active',
                    last_edited: new Date().toISOString(),
                },
            },
        }));
    }

    replaceSection(
        documentId: DocumentID,
        request: ReplaceSectionRequest
    ): TaskEither<ContentError, ReplaceSectionResponse> {
        console.log('StubContentClient.replaceSection called', documentId, request);
        return delayTaskEither(100, right({
            updated_section: {
                section_contents: request.edits,
                metadata: {
                    id: request.section_id,
                    name: 'Sample Section',
                    index: 1,
                    status: 'Active',
                    last_edited: new Date().toISOString(),
                },
            },
        }));
    }

    answerSectionQuestions(
        documentId: DocumentID,
        request: AnswerSectionQuestionsRequest
    ): TaskEither<ContentError, AnswerSectionQuestionsResponse> {
        console.log('StubContentClient.answerSectionQuestions called', documentId, request);
        return delayTaskEither(100, right({
            updated_section: {
                section_contents: request.answers + '\n\nAnswered questions.',
                metadata: {
                    id: request.section_id,
                    name: 'Sample Section',
                    index: 1,
                    status: 'Active',
                    last_edited: new Date().toISOString(),
                },
            },
        }));
    }

    generateSectionQuestions(
        documentId: DocumentID,
        request: GenerateSectionQuestionsRequest
    ): TaskEither<ContentError, GenerateSectionQuestionsResponse> {
        console.log('StubContentClient.generateSectionQuestions called', documentId, request);
        return delayTaskEither(100, right({
            probing_questions: 'Here are some sample probing questions for your section...',
        }));
    }

    answerDocumentQuestions(
        documentId: DocumentID,
        request: AnswerDocumentQuestionsRequest
    ): TaskEither<ContentError, Ok> {
        console.log('StubContentClient.answerDocumentQuestions called', documentId, request);
        return delayTaskEither(100, right(Ok));
    }

    generateDocumentQuestions(
        documentId: DocumentID
    ): TaskEither<ContentError, GenerateDocumentQuestionsResponse> {
        console.log('StubContentClient.generateDocumentQuestions called', documentId);
        return delayTaskEither(100, right({
            probing_questions: 'What is the purpose of this document?\n\nWhat are the key takeaways?',
        }));
    }

    appendSection(
        documentId: DocumentID,
        request: AppendSectionRequest
    ): TaskEither<ContentError, AppendSectionResponse> {
        console.log('StubContentClient.appendSection called', documentId, request);
        return delayTaskEither(100, right({
            new_section: {
                section_contents: null, // Initialize with null as per requirements
                metadata: {
                    id: randomUUID().toString(), // Use a mock ID for the stub
                    name: request.section_title,
                    index: 99, // Should always be the next integer, but that's hard to stub.
                    status: 'Unstarted',
                    last_edited: new Date().toISOString(),
                },
            },
        }));
    }

    uploadSupplementalDocument(
        documentId: DocumentID,
        request: UploadSupplementalDocumentRequest
    ): TaskEither<ContentError, Ok> {
        console.log('StubContentClient.uploadSupplementalDocument called', documentId, request);
        return delayTaskEither(100, right(Ok));
    }
}
