// @contexts/PaymentClient.context.tsx

import React, { createContext, useContext, ReactNode, useMemo, useEffect } from 'react';
import { PaymentClient } from '@services/clients/PaymentClient';
import { DefaultPaymentClient } from '@services/ouroboros/DefaultPaymentClient';
import { StubPaymentClient } from '@services/stubs/StubPaymentClient';
import { useIoBasedLoadingSetters } from '@contexts/Loading.context';
import { enabledFeatures, FeaturesEnum } from '@src/utils/enabledFeatures';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';
import { useIoState } from '@utils/useIoState';
import {Ok} from "@model/Ok";

interface PaymentClientContextType {
    paymentClient: PaymentClient;
}

const PaymentClientContext = createContext<PaymentClientContextType | undefined>(undefined);

export const PaymentClientProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [stripe, setStripe] = useIoState<Stripe | null>(null);
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useIoBasedLoadingSetters();

    // Memoize the creation of the payment client
    const paymentClient = useMemo<PaymentClient>(() => {
        return enabledFeatures[FeaturesEnum.StubClients]
            ? new StubPaymentClient() // Stub client if enabled
            : new DefaultPaymentClient(); // Default client otherwise
    }, []);

    // TaskEither to initialize Stripe with the fetched publishable key
    const initializeStripeWithKeyTask = useMemo(
        () => (key: string): TE.TaskEither<Error, Ok> =>
            pipe(
                TE.tryCatch(
                    () => loadStripe(key),
                    () => new Error('Failed to load Stripe')
                ),
                TE.flatMap((stripeInstance) =>
                    stripeInstance
                        ? TE.rightIO(setStripe(stripeInstance))
                        : TE.left(new Error('Stripe instance could not be loaded'))
                )
            ),
        [setStripe]
    );

    // Task to fetch the publishable key and initialize Stripe
    const fetchPublishableKeyTask: T.Task<Ok> = useMemo(
        () =>
            pipe(
                T.fromIO(triggerLoadingState), // Start loading state
                T.flatMap(() =>
                    pipe(
                        paymentClient.getPublishableKey(),
                        TE.flatMap((key) => initializeStripeWithKeyTask(key)),
                        TE.fold(
                            (error) => T.fromIO(triggerErrorState('Failed to fetch publishable key', error.message)),
                            () => T.fromIO(triggerSuccessState)
                        )
                    )
                )
            ),
        [paymentClient, triggerLoadingState, triggerErrorState, triggerSuccessState, initializeStripeWithKeyTask]
    );

    // Run the Stripe initialization task when the component mounts
    useEffect(() => {
        void fetchPublishableKeyTask(); // Execute the Task without returning anything
    }, [fetchPublishableKeyTask]);

    // Memoize the context value to prevent unnecessary re-renders
    const contextValue = useMemo(
        () => ({
            paymentClient,
        }),
        [paymentClient]
    );

    if (!stripe) {
        // Optionally show a loading state or spinner
        return null;
    }

    return (
        <PaymentClientContext.Provider value={contextValue}>
            <Elements stripe={stripe}>
                {children}
            </Elements>
        </PaymentClientContext.Provider>
    );
};

export const usePaymentClient = (): PaymentClientContextType => {
    const context = useContext(PaymentClientContext);
    if (!context) {
        throw new Error('usePaymentClient must be used within a PaymentClientProvider');
    }
    return context;
};
