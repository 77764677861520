// @utils/useIoState.ts
import { useState, useCallback } from 'react';
import * as IO from 'fp-ts/IO';
import {Ok} from "@model/Ok";

export function useIoState<S>(initialState: S): [S, (s: S) => IO.IO<Ok>] {
    const [state, setState] = useState<S>(initialState);

    // Memoize the IO-based setter to avoid unnecessary re-renders
    const setIoState = useCallback((newState: S): IO.IO<Ok> => {
        return  IO.as(Ok)(() => setState(newState));  // setState is effectful, and we're wrapping it in IO<void> then creating a concrete `Ok` return value
    }, []);

    return [state, setIoState];
}
