// @components/Prompt.tsx
import React, { useState, useRef } from "react"; // Import useRef
import { View, Text, TextInput, ScrollView, TouchableOpacity } from "react-native";
import { PromptStyles as styles } from "@styles/Prompt.style";
import { Markdown as MarkdownType } from "@model/markdown";
import Markdown from "react-native-markdown-display";
import * as T from "fp-ts/lib/Task"; // Import Task utilities
import { pipe } from "fp-ts/lib/function";
import { useIoState } from "@utils/useIoState"; // Replace useState with IO-based state management
import { MaterialIcons } from "@expo/vector-icons";
import {Ok} from "@model/Ok";
import { NativeSyntheticEvent, TextInputContentSizeChangeEventData } from 'react-native';

type PromptComponentProps = {
  probingQuestion: MarkdownType;
  onSubmitTask: (userInput: string) => T.Task<Ok>; // Task-based onSubmit
};

const PromptComponent: React.FC<PromptComponentProps> = ({
  probingQuestion,
  onSubmitTask,
}) => {
  const [userInput, setUserInput] = useIoState<string>(""); // Now using IO-based state
  const [inputHeight, setInputHeight] = useState(100); // Initial height set to 50
  const inputRef = useRef<TextInput>(null); // Create a ref for the TextInput

  const handleOnSubmit = () => {
    void pipe(
      onSubmitTask(userInput),
      T.flatMap(() => T.fromIO(setUserInput(""))) // Clear the input field after submission using IO-based setter
    )();
  };

  const handleContentSizeChange = (
      e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>
  ) => {
    const newHeight = e.nativeEvent.contentSize.height;
    if (newHeight > inputHeight && newHeight <= 150) {
      setInputHeight(newHeight);
    } else if (newHeight < 50) {
      setInputHeight(35);
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.header}>Probing Questions</Text>
      <ScrollView style={styles.probingQuestionContainer}>
        <Markdown>{probingQuestion}</Markdown>
      </ScrollView>
      
      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
        <View style={{ flex: 1 }}>
          <TextInput
            ref={inputRef}
            style={[styles.input, { height: inputHeight }]} 
            value={userInput}
            onChangeText={(text) => setUserInput(text)()} 
            placeholder="Type your answer here..."
            placeholderTextColor={'gray'}
            multiline={true}
            onContentSizeChange={handleContentSizeChange} 
            scrollEnabled={true} 
          />
        </View>
        <TouchableOpacity
          style={styles.button2}
          onPress={handleOnSubmit}
        >
          <MaterialIcons name="send" size={24} color="white" />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default PromptComponent;
