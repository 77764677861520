// @components/workspace/creation-flow/DocumentUpload.tsx
import React from "react";
import { View, Text } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { DocumentCreationStyles as styles } from "@styles/DocumentCreation.style";
import * as T from "fp-ts/lib/Task";
import * as TO from "fp-ts/lib/TaskOption";
import * as O from "fp-ts/lib/Option";
import * as IO from "fp-ts/lib/IO";
import { Ok } from "@model/Ok";

export type NextAction = {
    action: T.Task<Ok>;
    label: string;
};

type DocumentUploadProps = {
    uploadedDocument: O.Option<File>;
    onFileSelect: TO.TaskOption<Ok>;
    nextAction: NextAction;
    onPrevious: IO.IO<Ok>;
};

const DocumentUploadView: React.FC<DocumentUploadProps> = ({
                                                               uploadedDocument,
                                                               onFileSelect,
                                                               nextAction,
                                                               onPrevious,
                                                           }) => (
    <View style={styles.containerDoc}>
        <Text style={styles.heading}>Upload Your Document</Text>
        <TouchableOpacity style={styles.button3} onPress={onFileSelect}>
            <Text style={styles.buttonText}>
                {O.fold<File, string>(
                    () => "Upload a Document",
                    (file) => file.name
                )(uploadedDocument)}
            </Text>
        </TouchableOpacity>
        <View style={styles.viewStyle}>
            <TouchableOpacity style={styles.button2} onPress={ onPrevious}>
                <Text style={styles.buttonText}>Back</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button2} onPress={nextAction.action}>
                <Text style={styles.buttonText}>{nextAction.label}</Text>
            </TouchableOpacity>
        </View>
    </View>
);

export default DocumentUploadView;
