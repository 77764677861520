// @model/Ok.ts
import * as t from 'io-ts';

export const OkType = t.type({ success: t.literal(true) });
export type Ok = t.TypeOf<typeof OkType>;

const ok: Ok = { success: true };

export default ok;
export { ok as Ok };
