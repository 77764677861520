// @components/CreditCardInformation.tsx

import React from "react";
import { View } from "react-native";
import { Button, Title, Card, HelperText } from "react-native-paper";
import {
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import * as TE from "fp-ts/lib/TaskEither";
import * as T from "fp-ts/lib/Task";
import { pipe } from "fp-ts/lib/function";
import { sexyStyles } from "@styles/OuroborosSexyTheme.style";
import { BillingInfo, CardSetupError } from "@model/payments";
import { useIoBasedLoadingSetters } from "@contexts/Loading.context"; // Use IO-based loading context setters
import { handleCardSetupErrorIo } from "@utils/errorHandlers/cardSetupErrorHandler"; // IO-based error handler
import prepareBillingInfo from "@services/CreditCardService";
import {Ok} from "@model/Ok";

type CreditCardInformationProps = {
  submitBillingSetup: (
    billingInfo: BillingInfo
  ) => TE.TaskEither<CardSetupError, Ok>;
};

const CreditCardInformation: React.FC<CreditCardInformationProps> = ({
  submitBillingSetup,
}) => {
  const elements = useElements();
  const { triggerSuccessState, triggerErrorState, triggerLoadingState } =
    useIoBasedLoadingSetters(); // Use IO-based loading context setters

  const submitCard = () => {
    pipe(
      TE.fromIO(triggerLoadingState), // Set loading state
      TE.flatMap(() =>
        TE.fromNullable({
          type: "InitializationError" as const,
        } as CardSetupError)(elements)
      ),
      TE.flatMap((stripeElements) => prepareBillingInfo(stripeElements)),
      TE.flatMap((billingInfo) => submitBillingSetup(billingInfo)),
      TE.fold(
        (error) => T.fromIO(handleCardSetupErrorIo(error, triggerErrorState)), // Use IO-based error handling
        () => T.fromIO(triggerSuccessState) // Use IO-based success state
      )
    )();
  };

  // Style options for Stripe elements
  const styleOptions = {
    style: {
      base: {
        fontSize: "14px",
        color: "#000",
        fontFamily: "Roboto, Open Sans, sans-serif",
        fontWeight: "400",
        backgroundColor: "#fff",
        borderRadius: "10px",
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  return (
    <View style={sexyStyles.container}>
      <Card style={sexyStyles.card}>
        <Title style={sexyStyles.title}>Payment Information</Title>

        <HelperText
          style={{ marginLeft: -10, fontWeight: "bold", fontSize: 14 }}
          type="info"
        >
          Card Number
        </HelperText>
        <View style={sexyStyles.inputPayment}>
          <CardNumberElement options={styleOptions} />
        </View>

        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={sexyStyles.input}>
            <HelperText
              style={{ marginLeft: -10, fontWeight: "bold", fontSize: 14 }}
              type="info"
            >
              Expiry Date
            </HelperText>
            <View style={sexyStyles.inputPayment}>
              <CardExpiryElement options={styleOptions} />
            </View>
          </View>
          <View style={sexyStyles.input}>
            <HelperText
              style={{ marginLeft: -10, fontWeight: "bold", fontSize: 14 }}
              type="info"
            >
              CVC
            </HelperText>
            <View style={sexyStyles.inputPaymentCVC}>
              <CardCvcElement options={styleOptions} />
            </View>
          </View>
        </View>

        <View style={sexyStyles.input}>
          <HelperText
            style={{ marginLeft: -10, fontWeight: "bold", fontSize: 14 }}
            type="info"
          >
            Billing Address
          </HelperText>
          <AddressElement
            options={{
              mode: "billing",
            }}
          />
        </View>

        <Button mode="contained" onPress={submitCard} style={sexyStyles.button}>
          Save Card Information
        </Button>
      </Card>
    </View>
  );
};

export default CreditCardInformation;
